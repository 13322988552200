import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntdCheckbox } from 'antd';

import './styles.less';

const Checkbox = props => {
  const { rounded } = props;

  return (
    <AntdCheckbox {...props} className={rounded && 'rounded-checkbox'} />
  );
};

Checkbox.propTypes = {
  rounded: PropTypes.bool
};

export default Checkbox;