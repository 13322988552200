import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CartItem from './CartItem';
import TotalBox from '../TotalBox';
import { useCart } from '../../../providers';
import { Row, Text } from '../../../../../common';

const ItemsView = () => {
  const { items } = useCart();
  const { t } = useTranslation();

  return useMemo(() => (
    <div>
      <Row justify="center" mb={20}>
        <Text h2 white upper>
          {t`Cart items`}
        </Text>
      </Row>
      {items.map(({ product, options }) => {
        return options.map(option => (
          <CartItem
            key={option.id}
            product={product}
            option={option}
          />
        ));
      })}
      <TotalBox />
    </div>
  ), [t, items]);
};

ItemsView.propTypes = {};

export default ItemsView;