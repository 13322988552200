import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CloseIcon, Col, CurrencyFormatter, Row, Text } from '../../../../../../common';
import { useCart } from '../../../../providers';

const { REACT_APP_CLOUD_FRONT_URL } = process.env;

const CartItem = props => {
  const { product, option } = props;
  const { photos, info, price } = product;
  const { id, colorCode, size } = option;
  const { items, updateCart } = useCart();

  const handleOnRemoveClick = useCallback(
    optionId => () => {
      const item = document.getElementById(`cart-item-${optionId}`);

      item.addEventListener('animationend', () => {
        updateCart(
          items.reduce((acc, { product, options }) => {
            const newOptions = options.filter(_ => _.id !== optionId);
            return newOptions.length
              ? [...acc, { product, options: newOptions }]
              : acc;
          }, [])
        );
      });

      item.classList.add('removed');
    }, [items, updateCart]);

  return (
    <Row full>
      <Row id={`cart-item-${id}`} className="cart-item" full>
        <Row className="product-photo-container" mr={20}>
          {!!photos.length && (
            <img
              src={`${REACT_APP_CLOUD_FRONT_URL}/${photos[0].key}`}
              alt="product item"
            />
          )}
        </Row>
        <Row justify="space-between" className="info-wrapper">
          <Col>
            <Row mb={10}>
              <Text h3 white upper style={{ whiteSpace: 'break-spaces' }}>
                {info.title}
              </Text>
            </Row>
            <Row>
              <Text h3 white upper>
                <CurrencyFormatter amount={price.currentUah} curr="UAH" />
              </Text>
            </Row>
          </Col>
          <Row>
            <Col className="color" style={{ backgroundColor: colorCode }} />
            <Col className="size">{size}</Col>
          </Row>
        </Row>
        <Row className="close-icon-wrapper">
          <CloseIcon color="#8b8b8b" onClick={handleOnRemoveClick(id)} />
        </Row>
      </Row>
      <Row className="separator" />
    </Row>
  );
};

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired
};

export default CartItem;