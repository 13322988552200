import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  createNoPayOrder: params =>
    axios.post('/api/order/create/no-pay?identity=client', params)
};

function* createNoPayOrder({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();

  try {
    const { status, data } = yield call(Api.createNoPayOrder, params);
    if (status === 200) {
      onSuccess && onSuccess(data);
    }
  } catch (error) {
    onError && onError(error);
  }
}

export default createNoPayOrder;