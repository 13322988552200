import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import CheckoutView from './CheckoutView';
import ItemsView from './ItemsView';
import { useCart } from '../../providers';

import './styles.less';

const Cart = () => {
  const { view, CartViews, setItemsView } = useCart();

  useEffect(() => {
    return () => {
      setItemsView();
    };
  // eslint-disable-next-line
  }, [])

  return useMemo(() => {
    const steps = clsx(
      view === CartViews.ITEMS && 'step-0',
      view === CartViews.CHECKOUT && 'step-1'
    );

    return (
      <div className="cart-container">
        <div className="cart-content">
          <div className={clsx('cart-steps', steps)}>
            <div className={clsx(
              'checkout-item',
              view === CartViews.ITEMS && 'active'
            )}>
              <ItemsView />
            </div>
            <div className={clsx(
              'checkout-item',
              view === CartViews.CHECKOUT && 'active'
            )}>
              <CheckoutView />
            </div>
          </div>
        </div>
      </div>
    );
  }, [view, CartViews]);
};

Cart.propTypes = {};

export default Cart;