import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  signIn: ({ email, password }) =>
    axios.post('/api/client/signIn', { email, password })
};

function* signIn({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { data } = yield call(Api.signIn, params);
    onSuccess && onSuccess(data);
  } catch (error) {
    onError && onError();
  }
}

export default signIn;