import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, CurrencyFormatter, Row, Text } from '../../../../../common';
import { useUser } from '../../../../user';
import { useCart } from '../../../providers';
import useGTM, { Events } from '../../../../../common/hooks/useGTM';

import './styles.less';

const TotalBox = () => {
  const { t } = useTranslation();
  const { items, total, setCheckoutView } = useCart();
  const { userState } = useUser();
  const { user } = userState;
  const { pushEvent } = useGTM();

  const preTotal = useMemo(() => {
    return items.reduce((acc, { options, product }) => {
      return acc + product.price.currentUah * options.length;
    }, 0);
  }, [items]);

  const handleOnCheckoutClick = useCallback(
    () => {
      pushEvent(Events.cartCategory.checkoutClick());
      setCheckoutView();
    },
    [pushEvent, setCheckoutView]
  )

  return (
    <Row className="total-container">
      {user && (
        <>
          <Row full mb={10}>
            <Col xs={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Text h6 white upper right ghost>{t`Pre-total`}:</Text>
            </Col>
            <Col xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Text h6 white bold right ghost>
                <CurrencyFormatter amount={preTotal} curr="UAH" />
              </Text>
            </Col>
          </Row>
          <Row justify="space-between" full mb={20}>
            <Col xs={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Text h6 white upper ghost>{t`Personal discount`}:</Text>
            </Col>
            <Col xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Text h6 white bold ghost>-{user.discount} %</Text>
            </Col>
          </Row>
        </>
      )}
      <Row justify="space-between" full mb={20}>
        <Col xs={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Text h2 white upper>{t`Total`}:</Text>
        </Col>
        <Col xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Text h2 white bold>
            <CurrencyFormatter amount={total} curr="UAH" />
          </Text>
        </Col>
      </Row>
      <Row full>
        <Button fullWidth transparent onClick={handleOnCheckoutClick}>
          <Text h4 upper white>{t`Checkout`}</Text>
        </Button>
      </Row>
    </Row>
  );
};

TotalBox.propTypes = {};

export default TotalBox;