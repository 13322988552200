import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './overrides.css';

const LazyImage = props => {
  const {
    src,
    effect,
    visibleByDefault,
    title,
    afterLoad,
    beforeLoad,
    style,
    className
  } = props;

  return (
    <LazyLoadImage
      src={src}
      effect={effect}
      title={title}
      afterLoad={afterLoad}
      beforeLoad={beforeLoad}
      visibleByDefault={visibleByDefault}
      style={style}
      className={className}
    />
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  effect: PropTypes.oneOf(['blur', 'black-and-white', 'opacity']),
  visibleByDefault: PropTypes.bool,
  title: PropTypes.string,
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string
};

LazyImage.defaultProps = {
  style: {}
};

export { LazyImage };
