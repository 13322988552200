import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, CurrencyFormatter, Modal, Row, Text } from '../../../../common';

const DiscountDetail = () => {
  const { t } = useTranslation();

  return (
    <Modal
      btnComponent={
        <Button invisible>
          <Text p white center underline>
            {t('Detailed about discount')}
          </Text>
        </Button>
      }
      title={t`Discount program conditions`}
    >
      <Row mb={40}>
        <Text h4>{t`For our clients there is an accumulative discount system. After the registration each client gets 5% discount for the most of our products.`}</Text>
      </Row>
      <Row mb={20}>
        <Text h4>{t`Discount grows once the total amount of orders reaches the following figures:`}</Text>
      </Row>
      <Row justify="center" mb={10}>
        <Col xs={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Text h3 upper bold ghost>10%</Text>
        </Col>
        <Col xs={1} />
        <Col xs={13}>
          <Text h3 upper bold><CurrencyFormatter amount={150000} curr="UAH" /></Text>
        </Col>
      </Row>
      <Row justify="center" mb={10}>
        <Col xs={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Text h3 upper bold ghost>15%</Text>
        </Col>
        <Col xs={1} />
        <Col xs={13}>
          <Text h3 upper bold><CurrencyFormatter amount={500000} curr="UAH" /></Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Text h3 upper bold ghost>20%</Text>
        </Col>
        <Col xs={1} />
        <Col xs={13}>
          <Text h3 upper bold><CurrencyFormatter amount={1000000} curr="UAH" /></Text>
        </Col>
      </Row>
    </Modal>
  );
};

DiscountDetail.propTypes = {};

export default DiscountDetail;