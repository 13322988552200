import React, { createContext, useEffect, useCallback, useRef } from 'react';

export const PageScrollContext = createContext();

const PageScrollProvider = props => {
  const { children } = props;
  const reachBottomCallbacks = useRef([]);
  const reachTopCallbacks = useRef([]);

  const handleScroll = useCallback(
    () => {
      const isReachBottom = document.body.scrollHeight - (window.scrollY + window.innerHeight) < 1;
      const isReachTop = window.scrollY === 0;

      if (isReachBottom) {
        reachBottomCallbacks.current.forEach(({ cb }) => cb());
      }

      if (isReachTop) {
        reachTopCallbacks.current.forEach(({ cb }) => cb());
      }
    },
    []
  );

  const subscribeOnReachBottom = useCallback(
    (id, cb) => {
      reachBottomCallbacks.current = [
        ...reachBottomCallbacks.current,
        { id, cb }
      ]
    },
    []
  );

  const unsubscribeOnReachBottom = useCallback(
    id => {
      reachBottomCallbacks.current =
        reachBottomCallbacks.current.filter(_ => _.id !== id);
    },
    []
  );

  const subscribeOnReachTop = useCallback(
    (id, cb) => {
      reachBottomCallbacks.current = [
        ...reachBottomCallbacks.current,
        { id, cb }
      ]
    },
    []
  );

  const unsubscribeOnReachTop = useCallback(
    id => {
      reachBottomCallbacks.current =
        reachBottomCallbacks.current.filter(_ => _.id !== id);
    },
    []
  );

  useEffect(() => {
    document
      .addEventListener('scroll', handleScroll);

    return () => {
      document
        .removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line
  }, []);

  return (
    <PageScrollContext.Provider
      value={{
        subscribeOnReachBottom,
        unsubscribeOnReachBottom,
        subscribeOnReachTop,
        unsubscribeOnReachTop
      }}
    >
      {children}
    </PageScrollContext.Provider>
  );
};

PageScrollProvider.propTypes = {};

export default PageScrollProvider;