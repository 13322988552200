import React, { useCallback, useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import ErrorMessage from '../ErrorMessage';

import 'react-phone-input-2/lib/style.css';
import './styles.less';

const FormPhoneInput = props => {
  const { field, form, style = {}, black, ...restProps } = props;
  const [pValue, setPValue] = useState('');
  const { name: fieldName } = field;
  const { setFieldValue, values } = form;

  useEffect(() => {
    if (values[fieldName]) {
      const { dialCode } = values;
      const { onChange } = field;
      setPValue(`${dialCode}${values[fieldName]}`);
      onChange(values[fieldName]);
    }
    // eslint-disable-next-line
  }, []);

  const handleOnChange = useCallback(
    (value, { dialCode, countryCode }) => {
      const { onChange } = field;
      setPValue(value);
      onChange(value);
      const withNoDialCode = value.replace(/[^0-9]+/g,'').slice(dialCode.length);
      setFieldValue('phoneNumber', withNoDialCode);
      setFieldValue('countryCode', countryCode);
      setFieldValue('dialCode', +dialCode);
    },
    [field, setFieldValue]
  );

  const handleOnBlur = useCallback(
    () => {
      form.setTouched({ [restProps.f]: true });
    },
    [restProps, form]
  );

  return (
    <>
      <ReactPhoneInput
        countryCodeEditable={false}
        inputExtraProps={{ required: true, ...restProps }}
        inputClass={`phone-input${black ? ' black' : ' white'}`}
        containerStyle={{ marginBottom: '.25rem' }}
        inputStyle={{ ...style, borderRadius: 1, width: '100%', border: '1px solid #d9d9d9', fontFamily: 'FuturaLight' }}
        country={'ua'}
        regions={['america', 'europe']}
        value={pValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />

      {form.errors[fieldName] && form.touched[fieldName] && <ErrorMessage>{form.errors[fieldName]}</ErrorMessage>}
    </>
  );
};

FormPhoneInput.propTypes = {};

export default FormPhoneInput;