import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const Skeleton = props => {
  const { type } = props;

  return (
    <div className={`skeleton-${type}`} />
  );
};

Skeleton.propTypes = {
  type: PropTypes.oneOf(['white', 'dark']).isRequired
};

Skeleton.defaultProps = {
  type: 'white'
};

export default Skeleton;