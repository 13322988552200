import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeliveryForm from './DeliveryForm';
import { Button, Col, Row, Text } from '../../../../../common';
import Switcher from '../../Switcher';
import { Img } from '../..';
import { useCart } from '../../../providers';

import backArrow from '../../../../../assets/icons/back-arrow-white.svg';
import watsAppIcon from '../../../../../assets/icons/whatsapp-color.svg';
import viberIcon from '../../../../../assets/icons/viber-color.svg';

import './styles.less';


const {
  REACT_APP_SHOWROOM_PHONE_NUMBER
} = process.env;

const CheckoutView = () => {
  const { t } = useTranslation();
  const { setItemsView } = useCart();
  const [isUkraine, setIsUkraine] = useState(true);

  const text = useMemo(() =>
      t`Hey, I'd like to make an order`,
    [t]
  );

  const handleOnBackClick = useCallback(
    () => setItemsView(),
    [setItemsView]
  );

  const handleOnSwitcherChange = useCallback(
    value => {
      setIsUkraine(value);
    },
    []
  );

  return useMemo(() => (
    <div>
      <Row className="checkout-container">
        <Row justify="space-between" mb={20} full>
          <Img
            src={backArrow}
            alt="back arrow"
            style={{ cursor: 'pointer' }}
            onClick={handleOnBackClick}
          />
          <Text h2 white upper>
            {t`Delivery info`}
          </Text>
          <div />
        </Row>
        <Col xs={24} mb={30}>
          <Switcher
            onChange={handleOnSwitcherChange}
            left={t`Ukraine`}
            right={t`Worldwide`}
            upper
            bold
          />
        </Col>
        {isUkraine && (
          <Row mb={50}>
            <DeliveryForm />
          </Row>
        )}
        {!isUkraine && (
          <Row direction="column">
            <Row mb={20}>
              <Text h3 white center>
                {t`The current page is in the development yet. Contact us to make a it manually:`}
              </Text>
            </Row>
            <Row justify="center">
              <Row mr={30}>
                <a href={`https://api.whatsapp.com/send?phone=${REACT_APP_SHOWROOM_PHONE_NUMBER}&text=${text}`}>
                  <Button invisible style={{ padding: 0, height: 'auto' }}>
                    <Img size={40} src={watsAppIcon} alt="whatsapp" />
                  </Button>
                </a>
              </Row>
              <Row>
                <a href={`viber://chat?number=${REACT_APP_SHOWROOM_PHONE_NUMBER}&text=${text}`}>
                  <Button invisible style={{ padding: 0, height: 'auto' }}>
                    <Img size={40} src={viberIcon} alt="viber" />
                  </Button>
                </a>
              </Row>
            </Row>
          </Row>
        )}
      </Row>
    </div>
  ), [handleOnBackClick, t, handleOnSwitcherChange, isUkraine, text]);
};

CheckoutView.propTypes = {};

export default CheckoutView;