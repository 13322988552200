import { useCallback, useState } from 'react';
import { takeEvery } from 'redux-saga/effects';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import idx from 'idx';

import identify from './identify';
import signInSaga from './signIn';
import authSaga from './auth';
import registerSaga from './register';
import recoverPasswordSaga from './recoverPassword';
import sendRecoveryInfoSaga from './sendRecoveryInfo';
import { useNotification } from '../../../common';

import { useUser } from '../../user';

const { REACT_APP_GAZE_TOKEN } = process.env;

const IDENTIFY = 'IDENTIFY';
const SIGN_IN = 'SIGN_IN';
const AUTHENTICATE = 'AUTHENTICATE';
const REGISTER = 'REGISTER';
const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
const SEND_RECOVERY_INFO = 'SEND_RECOVERY_INFO';

export default [
  takeEvery(IDENTIFY, identify),
  takeEvery(SIGN_IN, signInSaga),
  takeEvery(AUTHENTICATE, authSaga),
  takeEvery(REGISTER, registerSaga),
  takeEvery(RECOVER_PASSWORD, recoverPasswordSaga),
  takeEvery(SEND_RECOVERY_INFO, sendRecoveryInfoSaga)
];

export const useAuthorization = () => {
  const dispatch = useDispatch();
  const { userState, setUserSuccess, setUserFail, setUserLoading, setUserReset } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorMessage } = useNotification();
  const { t } = useTranslation();

  const identify = useCallback(
    () => {
      dispatch({
        type: IDENTIFY,
        payload: {
          onSuccess: data => {
            const { client } = data;
            dispatch(setUserSuccess(client));
          },
          onError: () => dispatch(setUserFail()),
          onStart: () => dispatch(setUserLoading())
        }
      });
    },
    [dispatch, setUserSuccess, setUserFail, setUserLoading]
  );

  const signIn = useCallback(
    params => {
      dispatch({
        type: SIGN_IN,
        payload: {
          params,
          onSuccess: data => {
            const { client, token } = data;
            dispatch(setUserSuccess(client));
            localStorage.setItem(REACT_APP_GAZE_TOKEN, token);
          },
          onError: () => dispatch(setUserFail()),
          onStart: () => dispatch(setUserLoading('password'))
        }
      });
    },
    [dispatch, setUserSuccess, setUserFail, setUserLoading]
  );

  const authenticate = useCallback(
    params => {
      dispatch({
        type: AUTHENTICATE,
        payload: {
          params,
          onSuccess: data => {
            const { client, token } = data;
            dispatch(setUserSuccess(client));
            localStorage.setItem(REACT_APP_GAZE_TOKEN, token);
          },
          onError: error => {
            showErrorMessage({
              title: t`Authentication error`,
              description: error.request.responseText,
              icon: '😿'
            });
            dispatch(setUserFail());
          },
          onStart: () => dispatch(setUserLoading(params.provider))
        }
      });
    },
    [dispatch, setUserFail, setUserLoading, setUserSuccess, showErrorMessage, t]
  )

  const register = useCallback(
    params => {
      dispatch({
        type: REGISTER,
        payload: {
          params,
          onSuccess: data => {
            const { client, token } = data;
            dispatch(setUserSuccess(client));
            localStorage.setItem(REACT_APP_GAZE_TOKEN, token);
          },
          onError: error => {
            dispatch(setUserFail());
            if (idx(error, _ => _.request.responseText)) {
              showErrorMessage({
                title: t`Registration error`,
                description: error.request.responseText,
                icon: '😿'
              });
            }
          },
          onStart: () => dispatch(setUserLoading('password'))
        }
      });
    },
    [
      showErrorMessage,
      t,
      dispatch,
      setUserSuccess,
      setUserFail,
      setUserLoading
    ]
  );

  const recoveryPassword = useCallback(
    (params, cb) => {
      dispatch({
        type: RECOVER_PASSWORD,
        payload: {
          params,
          onSuccess: () => {
            setIsLoading(false);
            cb();
          },
          onError: error => {
            if (idx(error, _ => _.request.responseText)) {
              showErrorMessage({
                title: t`Password recovery error`,
                description: error.request.responseText,
                icon: '😿'
              });
            }
            setIsLoading(false);
          },
          onStart: () => {
            setIsLoading(true);
          }
        }
      });
    },
    [dispatch, showErrorMessage, setIsLoading, t]
  );

  const sendRecovery = useCallback(
    (params, cb) => {
      dispatch({
        type: SEND_RECOVERY_INFO,
        payload: {
          params,
          onSuccess: () => {
            setIsLoading(false);
            cb();
          },
          onError: () => setIsLoading(false),
          onStart: () => setIsLoading(true)
        }
      });
    },
    [setIsLoading, dispatch]
  );

  const logOut = useCallback(
    () => {
      localStorage.removeItem(REACT_APP_GAZE_TOKEN);
      dispatch(setUserReset());
    },
    [setUserReset, dispatch]
  );

  return {
    identify,
    signIn,
    authenticate,
    register,
    recoveryPassword,
    sendRecovery,
    logOut,

    isLoading,
    userState
  };
};