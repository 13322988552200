import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  register: ({ firstName, email, countryCode, dialCode, phoneNumber, password, rePassword }) =>
    axios.post('/api/client/signUp', { firstName, email, countryCode, dialCode, phoneNumber, password, rePassword })
};

function* register({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { data } = yield call(Api.register, params);
    onSuccess && onSuccess(data);
  } catch (error) {
    onError && onError(error);
  }
}

export default register;