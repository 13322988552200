import React, { Fragment, useCallback } from 'react';
import { Select } from 'antd';

import ErrorMessage from '../ErrorMessage';

const FormSelect = ({ field, form, children, ...props }) => {
  const { touched, setFieldValue, setFieldTouched } = form;
  const { onBlur } = props;
  const { name: fieldName } = field;

  const handleOnBlur = useCallback(
    fieldName => {
      setFieldTouched(fieldName);
      if (onBlur) {
        onBlur();
      }
    },
    [onBlur, setFieldTouched]
  );

  const handleChange = useCallback(
    value => {
      setFieldValue(fieldName, value);
    },
    [fieldName, setFieldValue]
  );

  return (
    <Fragment>
      <Select {...field} {...props} onChange={handleChange} onBlur={handleOnBlur}>
        {children}
      </Select>
      <div style={{ position: 'relative', height: 20, width: '100%' }}>
        {(touched[fieldName] && form.errors[fieldName]) && (
          <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{form.errors[fieldName]}</ErrorMessage>
        )}
      </div>
    </Fragment>
  );
};

FormSelect.propTypes = {};

export default FormSelect;