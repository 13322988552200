import { object, string, mixed, ref } from 'yup';

const getValidationSchema = t => object().shape({
  firstName: string().required(t`Mandatory`),
  email: string().email(t`Not valid email`).required(t`Mandatory`),
  phoneNumber: mixed().test(
    'is-ukrainian-number',
    t`Not valid phone number`,
    (value, { parent }) => {
      if (parent.countryCode !== 'ua') return true;
      return value && value.length === 9;
    }
  ).required(t`Mandatory`),
  countryCode: string().required(t`Mandatory`),
  dialCode: string().required(t`Mandatory`),
  password: string().required(t`Mandatory`),
  rePassword: string().oneOf([ref('password')], t`Password should match`)
    .required(t`Mandatory`)
});

export default getValidationSchema;