import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gazePartition: null,
  worldPartition: null
};

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    updateGazePartition(state, { payload }) {
      return {
        ...state,
        gazePartition: payload
      }
    },
    updateWorldPartition(state, { payload }) {
      return {
        ...state,
        worldPartition: payload
      }
    }
  }
});

export const {
  updateGazePartition,
  updateWorldPartition
} = treeSlice.actions;

export default treeSlice.reducer;