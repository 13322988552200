import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const el = document.createElement('div');

const BottomBarDataPortal = props => {
  const { children, beforeClose } = props;

  useEffect(() => {
    const barRoot = document.getElementById('bottom-bar-content');

    barRoot.appendChild(el);

    return () => {
      beforeClose && beforeClose();
      barRoot.removeChild(el);
    };
  }, [beforeClose]);

  return createPortal(children, el);
};

BottomBarDataPortal.propTypes = {
  children: PropTypes.node.isRequired,
  beforeClose: PropTypes.func
};

export default BottomBarDataPortal;