import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';

import BottomBarDataPortal from '../../../../common/components/BottomBarDataPortal';
import SubFilters from './SubFilters';
import { CloseIcon, FilterButton, Row, Swiper, Text } from '../../../../common';
import useGTM, { Events } from '../../../../common/hooks/useGTM';
import { useBottomBar } from '../../../../common/providers';
import { Skeleton } from '..';

import './styles.less';

const swiper = {
  slidesPerView: 'auto',
  freeMode: true,
  mousewheel: true,
  direction: 'horizontal'
};

const SortedItems = props => {
  const { items, checkedItems, dark, onFilterClick } = props;

  const [activeItems, inactiveItems] = useMemo(
    () => {
      return [
        items.filter(({ key }) => checkedItems.find(_ => _.key === key)),
        items.filter(({ key }) => !checkedItems.find(_ => _.key === key)),
      ];
    },
    [items, checkedItems]
  );

  return (
    <div className="filter-wrapper">
      <Swiper {...swiper}>
        {activeItems.map(({ key, label, value, children }) => (
          <FilterButton
            key={key}
            className="filter-item"
            style={{ paddingRight: 5, width: 'auto' }}
            checked
            onClick={() => onFilterClick({ key, value, children })}
          >
            <Row>
              <Text h5 style={{ marginRight: 15 }} bold>{label}</Text>
              <CloseIcon color="#8b8b8b" onClick={() => {}} />
            </Row>
          </FilterButton>
        ))}
        {inactiveItems.map(({ key, label, value, children }) => (
          <FilterButton
            key={key}
            className="filter-item"
            style={{ paddingRight: 5, width: 'auto' }}
            onClick={() => onFilterClick({ key, value, children })}
          >
            <Row>
              <Text h5 white={!dark} ghost={dark} style={{ marginRight: 15 }}>{label}</Text>
            </Row>
          </FilterButton>
        ))}
      </Swiper>
    </div>
  );
};

SortedItems.propTypes = {
  items: PropTypes.array.isRequired,
  checkedItems: PropTypes.array.isRequired,
  dark: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired
};

const UnsortedItems = props => {
  const { items, checkedItems, dark, onFilterClick } = props;

  return (
    <div className="filter-wrapper">
      <Swiper {...swiper}>
        {items.map(({ key, label, value, children }) => {
          const isActive = checkedItems.some(_ => _.key === key);

          return (
            <FilterButton
              key={key}
              className="filter-item"
              style={{ paddingRight: 5, width: 'auto' }}
              checked
              onClick={() => onFilterClick({ key, value, children })}
            >
              <Row>
                {!isActive && <Text h5 white={!dark} ghost={dark} style={{ marginRight: 15 }}>{label}</Text>}
                {isActive && <Text h5 style={{ marginRight: 15 }} bold>{label}</Text>}
                {isActive && <CloseIcon color="#8b8b8b" onClick={() => {}} />}
              </Row>
            </FilterButton>
          );
        })}
      </Swiper>
    </div>
  );
};

UnsortedItems.propTypes = {
  items: PropTypes.array.isRequired,
  checkedItems: PropTypes.array.isRequired,
  dark: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired
};

const Filters = props => {
  const { items, onChange, singleSelect, initialItems, dark, skipSorting } = props;
  const [checkedItems, setCheckedItems] = useState(initialItems);
  const [innerSelect, setInnerSelect] = useState();
  const { pushEvent } = useGTM();
  const { triggerBottomBar } = useBottomBar();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 100);
  }, []);

  const handleOnFilterClick = useCallback(
    ({ key, value, children }) => {
      const checkedItem = checkedItems.find(_ => _.key === key);

      let newCheckedItems;

      if (children) {
        setInnerSelect({ key, value, children });
        triggerBottomBar(true);
        return;
      } else if (singleSelect) {
        if (idx(checkedItem, _ => _.key === key)) return;
        newCheckedItems = [{ key, value }];
      } else {
        newCheckedItems = checkedItem
          ? checkedItems.filter(_ => _.key !== key)
          : [...checkedItems, { key, value }];
      }

      if (!checkedItem) {
        pushEvent(
          Events
            .productsCategory
            .availableProductsFilterClick({ label: `${key}_filter` })
        );
      }

      setCheckedItems(newCheckedItems);
      onChange(newCheckedItems);
    },
    [triggerBottomBar, checkedItems, singleSelect, onChange, pushEvent]
  );

  const handleOnSubmitSubFilters = useCallback(checkedSubFilters => {
    const { children, ...selectedItem } = innerSelect;
    const isEmptyFilters = !checkedSubFilters.length;
    const isFilterSelected = checkedItems.some(_ => _.key === selectedItem.key);

    if (isEmptyFilters && !isFilterSelected) {
      triggerBottomBar(false);
      setInnerSelect(null);
      return;
    }

    let newCheckedItems;

    if (!isEmptyFilters) {
      newCheckedItems = [{
        ...selectedItem,
        children: children.filter(_ => checkedSubFilters.includes(_.key))
      }];
    } else if (isFilterSelected) {
      newCheckedItems = checkedItems.filter(_ => _.key !== selectedItem.key);
    }

    setCheckedItems(newCheckedItems);
    triggerBottomBar(false);
    setInnerSelect(null);
    onChange(newCheckedItems);
  }, [innerSelect, triggerBottomBar, onChange, checkedItems]);

  const handleBeforeBarClose = useCallback(() => {}, []);

  return useMemo(() => {
    if (!display) {
      return (
        <div className="filter-container">
          <FilterButton
            style={{
              width: 100,
              height: 30,
              visibility: 'visible',
              marginRight: 5,
              padding: 0,
              border: 'none'
            }}
          >
            <Skeleton type="dark" />
          </FilterButton>
          <FilterButton
            style={{
              width: 100,
              height: 30,
              visibility: 'visible',
              marginRight: 5,
              padding: 0,
              border: 'none'
            }}
          >
            <Skeleton type="dark" />
          </FilterButton>
          <FilterButton
            style={{
              width: 100,
              height: 30,
              visibility: 'visible',
              marginRight: 5,
              padding: 0,
              border: 'none'
            }}
          >
            <Skeleton type="dark" />
          </FilterButton>
        </div>
      );
    }

    const { key, children } = innerSelect || {};
    const { children: checkedChildren } = checkedItems
      .find(_ => _.key === key) || { children: [] };
    const initialCheckedItems = (children || [])
      .filter(c => checkedChildren.find(cc => cc.key === c.key))
      .map(_ => _.key);

    return (
      <div className="filter-container">
        {skipSorting && (
          <UnsortedItems
            items={items}
            checkedItems={checkedItems}
            onFilterClick={handleOnFilterClick}
            dark={dark}
          />
        )}
        {!skipSorting && (
          <SortedItems
            items={items}
            checkedItems={checkedItems}
            onFilterClick={handleOnFilterClick}
            dark={dark}
          />
        )}
        <BottomBarDataPortal beforeClose={handleBeforeBarClose}>
          <>
            {children && (
              <SubFilters
                items={children}
                onSubmit={handleOnSubmitSubFilters}
                initialCheckedItems={initialCheckedItems}
              />
            )}
          </>
        </BottomBarDataPortal>
      </div>
    );
  }, [
    display,
    innerSelect,
    skipSorting,
    items,
    checkedItems,
    handleOnFilterClick,
    dark,
    handleBeforeBarClose,
    handleOnSubmitSubFilters
  ]);
};

Filters.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.any.isRequired
  })),
  onChange: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialItems: PropTypes.array,
  dark: PropTypes.bool,
  skipSorting: PropTypes.bool
};

Filters.defaultProps = {
  items: [],
  onChange: () => {},
  initialItems: []
}

export default Filters;