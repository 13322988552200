import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';

import { DiscountDetails } from '..';
import { Button, Row, Text } from '../../../../common';
import { useUser } from '../../providers';
import { useAuthorization } from '../../../authorization';

import './styles.less';

const Profile = () => {
  const { t } = useTranslation();
  const { userState } = useUser();
  const { logOut } = useAuthorization();
  const { user } = userState;

  return (
    <Row justify="space-between" direction="column" fullHeight>
      <Row direction="column">
        <Row mb={50} justify="center">
          <div className="avatar-container">
            <UserOutlined style={{ fontSize: 40 }} />
          </div>
        </Row>
        <Row mb={40} justify="center">
          <Text white h1>{t('Hey, {{name}}', { name: user.firstName })}</Text>
        </Row>
        <Row mb={10} justify="center">
          <Text white h3>
            {t('Your discount - {{discount}}%', { discount: user.discount })}
          </Text>
        </Row>
        <Row mb={50} justify="center">
          <DiscountDetails />
        </Row>
      </Row>

      <Row justify="center">
        <Button transparent onClick={logOut}>
          {t`Log out`}
        </Button>
      </Row>
    </Row>
  );
};

Profile.propTypes = {};

export default Profile;