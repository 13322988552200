import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, Row, Text } from '../../../../../common';
import { FormInput } from '../../../../../common/components/FormAdapters';

import getValidationSchema from './validationSchema';
import initialValues from './initialValues';

const ForgotPasswordForm = props => {
  const { onSubmit, loading } = props;
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () => getValidationSchema(t),
    [t]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form>
            <Row>
              <Row mb={10} full>
                <Field
                  placeholder={t('Email')}
                  component={FormInput}
                  name="email"
                  type="email"
                  f="email"
                  style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                />
              </Row>
              <Row justify="center" mb={20} full>
                <Text h4 white center>
                  {t`Enter your email and we will send you further directions.`}
                </Text>
              </Row>
            </Row>

            <Row>
              <Button
                htmlType="submit"
                loading={loading}
                fullWidth
                disabled={!isValid || loading}
              >
                {t`Submit`}
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default ForgotPasswordForm;