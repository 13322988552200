import React, { useCallback, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { Text, Button, Row } from '../../../../common';
import { FormInput, FormPhoneInput } from '../../../../common/components/FormAdapters';
import { useAuthorization } from '../../sagas';

import './styles.less';

import backArrow from '../../../../assets/icons/back-arrow-white.svg';

import initialValues from './initialValues';
import getValidationSchema from './validationSchema';

const Registration = props => {
  const { t } = useTranslation();
  const { onBackClick } = props;
  const { register, userState } = useAuthorization();
  const { isLoading } = userState;

  const validationSchema = useMemo(
    () => getValidationSchema(t),
    [t]
  );

  const handleOnSubmit = useCallback(
    values => {
      register(values);
    },
    [register]
  );

  return (
    <div className="signup-container">
      <Row justify="space-between" mb={20}>
        <img
          src={backArrow}
          alt="back arrow"
          width="25"
          height="25"
          style={{ cursor: 'pointer' }}
          onClick={onBackClick}
        />
        <Text h2 white upper>
          {t`Registration`}
        </Text>
        <div />
      </Row>
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ isValid }) => {

          return (
            <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t('What is your name?')}
                    component={FormInput}
                    name="firstName"
                    f="firstName"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t('Email')}
                    component={FormInput}
                    name="email"
                    type="email"
                    f="email"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t`Phone`}
                    component={FormPhoneInput}
                    name="phoneNumber"
                    type="phoneNumber"
                    f="phoneNumber"
                    inputStyle={{
                      backgroundColor: 'transparent',
                      color: 'white',
                      fontSize: 16
                    }}
                  />
                </Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t('Password')}
                    component={FormInput}
                    name="password"
                    type="password"
                    f="password"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t('Confirm password')}
                    component={FormInput}
                    name="rePassword"
                    type="password"
                    f="rePassword"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
              </Row>

              <Row>
                <Button
                  htmlType="submit"
                  fullWidth
                  disabled={!isValid || isLoading}
                  loading={isLoading}
                >
                  {t`Submit`}
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

Registration.propTypes = {};

export default Registration;