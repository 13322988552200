import React, { useCallback, useEffect, useState, useRef } from 'react';
import { CloseCircleFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';

import ErrorMessage from '../ErrorMessage';

import './styles.less';

const FormSearcher = props => {
  const {
    field,
    form,
    onSearch,
    onSelect,
    onClear,
    loading,
    disabled,
    placeholder,
    inputStyle = {},
    white,
    ...restProps
  } = props;

  const { values, setFieldValue, errors, touched, setFieldTouched } = form;
  const [input, setInput] = useState({ value: null, search: false });
  const ref = useRef();

  useEffect(
    () => {
      const isOptionsExist = !!props.options.length;
      if (!isOptionsExist) {
        setInput({ value: null, search: false });
      }
    },
  // eslint-disable-next-line
    [props.options, values]
  );

  const onDeleteClick = useCallback(
    e => {
      e.stopPropagation();
      setInput({ value: null, search: false });
      setFieldValue(field.name, '');
      onClear && onClear();
    },
    [field.name, setFieldValue, onClear]
  );

  const icon = loading
    ? <LoadingOutlined />
    : (values[field.name] ? <CloseCircleFilled onClick={onDeleteClick} /> : <SearchOutlined />);

  useEffect(() => {
    if (input.search) {
      const timeoutId = setTimeout(() => onSearch(input.value), 500);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line
  }, [input]);

  const handleChange = useCallback(
    value => {
      setInput({ value, search: true });
      field.value && setFieldValue(field.name, undefined);
    },
    [setInput, setFieldValue, field]
  );

  const handleOnSelect = useCallback(
    value => {
      const item = restProps.options.find(_ => _.value === value);
      onSelect(item);
      setFieldValue(field.name, item);
      setInput({ value: item.label, search: false });
    },
    [
      field.name,
      restProps.options,
      onSelect,
      setFieldValue,
      setInput
    ]
  );

  const handleBlur = useCallback(
    () =>
      setFieldTouched(field.name, true),
    [setFieldTouched, field.name]
  );

  return (
    <>
      <AutoComplete
        {...field}
        {...restProps}
        ref={ref}
        onSelect={handleOnSelect}
        onBlur={handleBlur}
        onSearch={handleChange}
        value={input.value}
        disabled={disabled}
      >
        <Input
          suffix={icon}
          placeholder={placeholder}
          style={inputStyle}
          autocomplete={field.name}
          className={white ? 'white': 'black'}
        />
      </AutoComplete>
      <div style={{ position: 'relative', height: 20, width: '100%' }}>
        {errors[props.f] && touched[props.f] && (
          <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{errors[props.f]}</ErrorMessage>
        )}
      </div>
    </>
  );
};

FormSearcher.propTypes = {};

export default FormSearcher;