import { createSlice } from '@reduxjs/toolkit';

import { SAGA_FAIL, SAGA_INIT, SAGA_SUCCESS, SAGA_LOADING } from '../../../common';

const initialState = {
  user: undefined,
  authType: undefined,
  ...SAGA_INIT,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSuccess(state, { payload }) {
      return {
        ...state,
        user: payload,
        authType: undefined,
        ...SAGA_SUCCESS
      }
    },
    setUserFail(state) {
      return {
        ...state,
        user: null,
        authType: undefined,
        ...SAGA_FAIL
      }
    },
    setUserLoading(state, { payload }) {
      return {
        ...state,
        ...SAGA_LOADING,
        authType: payload
      }
    },
    setUserReset() {
      return {
        ...initialState
      }
    },
  }
});

export const {
  setUserSuccess,
  setUserFail,
  setUserLoading,
  setUserReset
} = userSlice.actions;

export default userSlice.reducer;