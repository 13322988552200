import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { CloseIcon, Col, Row, Text } from '..';
import { NotificationContext, NotificationType } from '../../providers';

import './styles.less';

export const useNotification = () => {
  const { showNotification } = useContext(NotificationContext);

  const showInfoMessage = useCallback(
    ({ title, description, duration, icon }) => {
      showNotification({
        title,
        description,
        duration,
        icon,
        type: NotificationType.Info
      })
    },
    [showNotification]
  );

  const showSuccessMessage = useCallback(
    ({ title, description, duration, icon }) => {
      showNotification({
        title,
        description,
        duration,
        icon,
        type: NotificationType.Success
      })
    },
    [showNotification]
  );

  const showErrorMessage = useCallback(
    ({ title, description, duration, icon }) => {
      showNotification({
        title,
        description,
        duration,
        icon,
        type: NotificationType.Error
      })
    },
    [showNotification]
  );

  return { showInfoMessage, showSuccessMessage, showErrorMessage };
};

const Notification = () => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [notificationKey, setNotificationKey] = useState(0);
  const { options } = useContext(NotificationContext);
  const { title, description, duration, type, icon } = options;

  useEffect(() => {
    if (title || description) {
      clearTimeout(ref.current);
      setNotificationKey(notificationKey + 1)
      setIsVisible(true);

      ref.current = setTimeout(() => {
        setIsVisible(false);
      }, duration);
    }
    // eslint-disable-next-line
  }, [options]);

  const handleOnCloseClick = useCallback(
    () => {
      clearTimeout(ref.current);
      setIsVisible(false);
    },
    [setIsVisible]
  );

  return (
    <Row key={`notification-${notificationKey}`}
         className={clsx(
        'notification-container',
           isVisible && 'visible',
           type
         )}
         align="center"
    >
      <Col xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <Text h1 style={{ fontSize: 30 }}>{icon}</Text>
      </Col>
      <Col xs={19}>
        {title && <Text h3 upper white>{title}</Text>}
        {description && <Text h4 newLine white>{description}</Text>}
      </Col>
      <div className="close-icon-wrapper">
        <CloseIcon color="#ffffff" onClick={handleOnCloseClick} />
      </div>
    </Row>
  );
};

Notification.propTypes = {};

export default Notification;