import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.less';

const BurgerToggle = props => {
  const { onClick, isOpen, color } = props;
  const [isClicking, setIsClicking] = useState(false);

  return (
    <div
      className={clsx(
        'burger-wrapper',
        isOpen && 'opened',
        isClicking && 'active'
      )}
      onClick={onClick}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onTouchStart={() => setIsClicking(true)}
      onTouchEnd={() => setIsClicking(false)}
    >
      <div className="line1" style={{ backgroundColor: color }} />
      <div className="line2" style={{ backgroundColor: color }} />
      <div className="line3" style={{ backgroundColor: color }} />
    </div>
  );
};

BurgerToggle.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  color: PropTypes.string.isRequired
};

BurgerToggle.defaultProps = {
  color: '#ffffff'
};

export default BurgerToggle;