import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  auth: ({ code, provider }) =>
    axios.post(`/api/client/authenticate/${provider}`, { code })
};

function* auth({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { data } = yield call(Api.auth, params);
    onSuccess && onSuccess(data);
  } catch (error) {
    onError && onError(error);
  }
}

export default auth;