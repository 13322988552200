import { createSlice } from '@reduxjs/toolkit';

const CartViews = {
  ITEMS: 'ITEMS_VIEW',
  CHECKOUT: 'CHECKOUT_VIEW'
};

const initialState = {
  items: [],
  calculating: false,
  total: 0,
  view: CartViews.ITEMS,
  checkoutSent: false
};

const menuSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateItems(state, { payload }) {
      return {
        ...state,
        items: payload
      }
    },
    setCalculating(state, { payload }) {
      return {
        ...state,
        calculating: payload
      }
    },
    setTotal(state, { payload }) {
      return {
        ...state,
        calculating: false,
        total: payload
      }
    },
    setView(state, { payload }) {
      return {
        ...state,
        view: payload
      }
    },
    setCart(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    }
  }
});

export const {
  updateItems,
  setTotal,
  setCalculating,
  setView,
  setCart
} = menuSlice.actions;

export { CartViews };

export default menuSlice.reducer;