import React, { useMemo, useState } from 'react';
import { Button as AntdButton } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './style.less';

const Button = props => {
  const { children, transparent, invisible, fullWidth, className, ...restProps } = props;
  const [isClicking, setIsClicking] = useState(false);

  const isDefault = useMemo(
    () => !transparent && !invisible,
    [transparent, invisible]
  );

  return (
    <AntdButton
      {...restProps}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onTouchStart={() => setIsClicking(true)}
      onTouchEnd={() => setIsClicking(false)}
      className={clsx(
        transparent && 'button-transparent',
        invisible && 'button-invisible',
        isDefault && 'button-default',
        fullWidth && 'full-width',
        isClicking && 'active',
        className
      )}
    >
      {children}
    </AntdButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  transparent: PropTypes.bool,
  invisible: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
};

Button.defaultProps = {
  className: '',
  style: {}
};

export default Button;