import { useCallback } from 'react';

const useQueryParams = () => {

  const transformArrays = useCallback(
    params => {
      return Object
        .keys(params)
        .reduce((acc, key) => {
          const value = params[key];
          return {
            ...acc,
            [key]: Array.isArray(value)
              ? value.reduce((acc, _) => acc.length ? `${acc},${_}` : `${_}`, '')
              : value
          };
        }, {});
    }, []
  );

  return { transformArrays };
};

export default useQueryParams;