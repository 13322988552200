import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpened: null
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    trigger(state, { payload }) {
      return {
        ...state,
        menuOpened: payload === state.menuOpened
          ? null
          : payload
      }
    },
  }
});

export const { trigger } = menuSlice.actions;

export default menuSlice.reducer;