import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects';

import { Fallback, Notification } from './common';
import { PageScrollProvider, NotificationProvider } from './common/providers';

import reportWebVitals from './reportWebVitals';

import './styles/styles.less';

const isAdmin = window.location.href.includes('/admin');
(isAdmin ? import('./admin') : import('./client'))
  .then(({ default: module, locales, App }) => {
    const { default: i18n } = require('./i18n');

    i18n(locales);

    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
      reducer: module.getReducers(),
      middleware: [sagaMiddleware]
    });

    sagaMiddleware.run(function* rootSaga() {
      yield all(module.getSagas());
    });

    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <NotificationProvider>
              <PageScrollProvider>
                <Suspense fallback={<Fallback />}>
                  <App />
                  <Notification />
                </Suspense>
              </PageScrollProvider>
            </NotificationProvider>
          </Provider>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });