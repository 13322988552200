import { Module } from '../../utils';
import reducers from './reducers';
import sagas from './sagas';

export default new Module({
  reducers,
  sagas
});

export * from './components';
export * from './providers';