import React, { useCallback } from 'react';
import { Input } from 'antd';

import ErrorMessage from '../ErrorMessage';

const FormTextArea = ({ field, form, className = '', ...props }) => {
  const { touched, setFieldTouched, setFieldValue } = form;
  const { onBlur } = props;
  const { name: fieldName } = field;

  const handleOnBlur = useCallback(
    () => {
      setFieldTouched(fieldName);
      if (onBlur) {
        onBlur();
      }
    },
    [setFieldTouched, fieldName, onBlur]
  );

  const handleOnChange = useCallback(
    ({ target }) => {
      setFieldValue(fieldName, target.value)
    },
    [fieldName, setFieldValue]
  );

  return (
    <>
      <Input.TextArea
        {...field}
        {...props}
        className={`${className}`}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
      />
      <div style={{ position: 'relative', height: 20, width: '100%' }}>
        {(touched[fieldName] && form.errors[fieldName]) && (
          <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{form.errors[fieldName]}</ErrorMessage>
        )}
      </div>
    </>
  );
};

FormTextArea.propTypes = {};

export default FormTextArea;