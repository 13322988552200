import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const Badge = forwardRef((props, ref) => {
  const { text } = props;

  return (
    <div ref={ref} className="badge">
      {text}
    </div>
  );
});

Badge.propTypes = {
  text: PropTypes.any.isRequired
};

export default Badge;