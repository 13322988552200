import React, { useState, useCallback, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';

import { DiscountDetails } from '../../../user';
import { Button, Oauth2Dialog, Row, Text } from '../../../../common';
import { FormInput } from '../../../../common/components/FormAdapters';
import { useAuthorization } from '../../sagas';
import { Img } from '../../../common';

import facebookWhite from '../../../../assets/icons/facebook-auth-white.svg';
import facebookColor from '../../../../assets/icons/facebook-auth-color.svg';
import googleWhite from '../../../../assets/icons/google-auth-white.svg';
import googleColor from '../../../../assets/icons/google-auth-color.svg';

import getValidationSchema from './validationSchema';
import initialValues from './initialValues';

import './styles.less';

const {
  REACT_APP_FB_AUTH_URL,
  REACT_APP_FB_CLIENT_ID,
  REACT_APP_FB_REDIRECT_URL,
  REACT_APP_GOOGLE_AUTH_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_REDIRECT_URL,
  REACT_APP_GOOGLE_SCOPE
} = process.env;

const Login = props => {
  const { onJoinClick, onForgotPasswordClick } = props;
  const { t } = useTranslation();
  const [hover, setHover] = useState(null);
  const { signIn, userState, authenticate } = useAuthorization();
  const { isLoading, authType } = userState;

  const validationSchema = useMemo(
    () => getValidationSchema(t),
    [t]
  );

  const handleSignIn = useCallback(
    values => {
      signIn(values);
    },
    [signIn]
  );

  const handleAuthenticate = useCallback(
    provider => code => {
      authenticate({ provider, code });
    },
    [authenticate]
  );

  const facebookLoginButton = useMemo(() => {
    if (isLoading && authType === 'facebook') {
      return <LoadingOutlined style={{ height: 15, width: 15, marginRight: 3, color: 'white' }} />;
    }

    return (
      <Img
        onMouseEnter={() => setHover('facebook')}
        onMouseLeave={() => setHover(null)}
        src={hover === 'facebook' ? facebookColor : facebookWhite}
        alt="facebook auth button"
        style={{ cursor: 'pointer', marginRight: 3 }}
        size={15}
      />
    );
  }, [hover, isLoading, authType]);

  const googleLoginButton = useMemo(() => {
    if (isLoading && authType === 'google') {
      return <LoadingOutlined style={{ height: 15, width: 15, marginLeft: 3, color: 'white' }} />;
    }

    return (
      <Img
        onMouseEnter={() => setHover('google')}
        onMouseLeave={() => setHover(null)}
        src={hover === 'google' ? googleColor : googleWhite}
        alt="google auth button"
        style={{ cursor: 'pointer', marginLeft: 3 }}
        size={15}
      />
    );
  }, [hover, isLoading, authType]);

  return useMemo(() => (
    <div className="login-container">
      <Row justify="center" mb={5}>
        <Text h2 white upper>
          {isLoading ? t(`Login using {{authType}}`, { authType }) : t`Login`}
        </Text>
      </Row>
      <Row justify="center" mb={20}>
        <Oauth2Dialog
          authUrl={REACT_APP_FB_AUTH_URL}
          clientId={REACT_APP_FB_CLIENT_ID}
          redirectUrl={REACT_APP_FB_REDIRECT_URL}
          onSuccess={({ code }) => handleAuthenticate('facebook')(code)}
          loginButton={facebookLoginButton}
        />
        <Oauth2Dialog
          authUrl={REACT_APP_GOOGLE_AUTH_URL}
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          redirectUrl={REACT_APP_GOOGLE_REDIRECT_URL}
          scope={REACT_APP_GOOGLE_SCOPE}
          onSuccess={({ code }) => handleAuthenticate('google')(code)}
          loginButton={googleLoginButton}
        />
      </Row>
      <Row fullHeight direction="column" justify="space-between">
        <Formik
          onSubmit={handleSignIn}
          validationSchema={validationSchema}
          initialValues={initialValues}
          validateOnMount
        >
          {({ isValid }) => {

            return (
              <Form style={{ width: '100%' }}>
                <Row mb={10}>
                  <Field
                    placeholder={t('Email')}
                    component={FormInput}
                    name="email"
                    type="email"
                    f="email"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row full mb={10}>
                  <Field
                    placeholder={t('Password')}
                    component={FormInput}
                    name="password"
                    type="password"
                    f="password"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row justify="center" mb={10}>
                  <Text h4 white onClick={onForgotPasswordClick} style={{ cursor: 'pointer' }} underline>
                    {t('Forgot password?')}
                  </Text>
                </Row>
                <Row>
                  <Button
                    htmlType="submit"
                    fullWidth
                    disabled={!isValid || isLoading}
                    loading={isLoading}
                  >
                    {t`Login`}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
        <Row justify="center" align="flex-end">
          <Row justify="center" mb={20}>
            <Text h2 white upper>
              {t`Registration`}
            </Text>
          </Row>
          <Row justify="center" mb={20}>
            <Text p white center>
              {t`Join the GAZE family and get a discount for the most of our products`}
            </Text>
          </Row>
          <Row justify="center">
            <DiscountDetails />
          </Row>
        </Row>
        <Row full>
          <Button onClick={onJoinClick} transparent fullWidth>
            {t`Join`}
          </Button>
        </Row>
      </Row>
    </div>
  ), [
    authType,
    facebookLoginButton,
    googleLoginButton,
    handleAuthenticate,
    handleSignIn,
    isLoading,
    onForgotPasswordClick,
    onJoinClick,
    t,
    validationSchema
  ]);
};

Login.propTypes = {};

export default Login;