import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import idx from 'idx';

const Oauth2Dialog = props => {
  const {
    authUrl,
    clientId,
    redirectUrl,
    loginButton,
    scope,
    onSuccess
  } = props;

  const openPopup = useCallback(
    url => {
      const options = {
        width: 580,
        height: 400,
        top: window.screenY + (window.outerHeight - 580) / 2.5,
        left: window.screenX + (window.outerWidth - 400) / 2
      };

      const popup = window.open(
        url,
        '_blank',
        qs.stringify(options).replaceAll('&', ',')
      );

      if (url === 'about:blank') {
        popup.document.body.innerHTML = 'Loading...';
      }

      const polling = setInterval(() => {

        if (!popup || popup.closed) {
          clearInterval(polling);
        }

        try {
          const code = idx(/^.+code=(.[^&]+).*$/.exec(popup.location.href), _ => _[1]);
          if (code) {
            onSuccess({ code: decodeURIComponent(code) });
            popup.close();
          }
        } catch (error) {
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
          // A hack to get around same-origin security policy errors in Internet Explorer.
        }
      }, 500);
    },
    [onSuccess]
  );

  const handleOnClick = useCallback(
    () => {
      const queryParams = {
        scope,
        client_id: clientId,
        redirect_uri: redirectUrl,
        response_type: 'code',
        display: 'popup'
      };

      openPopup(`${authUrl}?${qs.stringify(queryParams)}`);
    },
    [authUrl, clientId, openPopup, redirectUrl, scope]
  );

  return React
    .cloneElement(
      loginButton,
      {
        onClick: handleOnClick
      });
};

Oauth2Dialog.propTypes = {
  authUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  loginButton: PropTypes.node.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default Oauth2Dialog;