import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import FormSearcher from '../../../../../../../common/components/FormAdapters/FormSearcher';
import { useNP } from '../../../../../sagas';

const BranchField = props => {
  const { setFieldValue, values } = props;
  const { city } = values;
  const { t } = useTranslation();
  const { findBranches, branches, branchesLoading } = useNP();
  const [branchesOptions, setBranchesOptions] = useState([]);

  useEffect(() => {
    if (city) {
      findBranches(city.value);
    } else {
      setBranchesOptions([]);
      setFieldValue('branch', '');
    }
  // eslint-disable-next-line
  }, [city, findBranches]);

  useEffect(() => {
    setBranchesOptions(
      branches.map(({ Description: label, Ref: value }) => ({ label, value }))
    );
  }, [branches]);

  const handleOnBranchSearch = useCallback(
    value => {
      setBranchesOptions(
        branches
          .map(({ Description: label, Ref: value }) => ({ label, value }))
          .filter(_ => _.label.toLowerCase().includes(value.toLowerCase()))
      );
    },
    [setBranchesOptions, branches]
  );

  const handleOnBranchSelect = useCallback(
    ({ value }) => {
      setFieldValue('branch', value);
    },
    [setFieldValue]
  );

  const handleOnBranchClear = useCallback(
    () => {
      setBranchesOptions(branches.map(({ Description: label, Ref: value }) => ({ label, value })));
    },
    [setBranchesOptions, branches]
  );

  return (
    <Field
      white
      placeholder={t`Nova Poshta branch`}
      component={FormSearcher}
      name="branch"
      f="branch"
      options={branchesOptions}
      inputStyle={{
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: 16
      }}
      loading={branchesLoading}
      disabled={!city || branchesLoading}
      onSearch={handleOnBranchSearch}
      onSelect={handleOnBranchSelect}
      onClear={handleOnBranchClear}
    />
  );
};

BranchField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default BranchField;