import { useMemo } from 'react';
import PropTypes from 'prop-types';

const currencyMapper = {
  UAH: '₴',
  USD: '$',
  EUR: '€'
};

const transformNumber = x => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const transformCurrency = (amount, curr) => {
  return `${transformNumber(amount)} ${currencyMapper[curr]}`;
};

const CurrencyFormatter = props => {
  const { amount, curr } = props;
  return useMemo(() => transformCurrency(amount, curr), [amount, curr]);
};

CurrencyFormatter.propTypes = {
  amount: PropTypes.number.isRequired,
  curr: PropTypes.oneOf(['UAH', 'USD', 'EUR'])
};

export default CurrencyFormatter;