import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSentMessage from './ForgotPasswordSentMessage';
import { Img } from '../../../common';
import { Row, Text } from '../../../../common';
import { useAuthorization } from '../../sagas';

import backArrow from '../../../../assets/icons/back-arrow-white.svg';

import './styles.less';

const ForgotPassword = props => {
  const { t } = useTranslation();
  const { onBackClick } = props;
  const { sendRecovery, isLoading } = useAuthorization();
  const [isSent, setIsSent] = useState(false);

  const handleForgotPassword = useCallback(
    values => {
      sendRecovery(
        values,
        () => setIsSent(true)
      );
    },
    [sendRecovery, setIsSent]
  );

  return (
    <div className="forgot-password-container">
      <Row justify="space-between" mb={20}>
        <Img
          src={backArrow}
          alt="back arrow"
          style={{ cursor: 'pointer' }}
          onClick={onBackClick}
        />
        <Text h2 white upper>
          {t`Forgot password`}
        </Text>
        <div />
      </Row>
      {!isSent && (
        <ForgotPasswordForm
          onSubmit={handleForgotPassword}
          loading={isLoading}
        />
      )}
      {isSent && <ForgotPasswordSentMessage />}
    </div>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;