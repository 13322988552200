import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.less';

const CloseIcon = props => {
  const { color, style, className, ...restProps } = props;
  const [isClicking, setIsClicking] = useState(false);

  return (
    <div
      {...restProps}
      className={clsx(
        'close-wrapper',
        isClicking && 'active',
        className
      )}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onTouchStart={() => setIsClicking(true)}
      onTouchEnd={() => setIsClicking(false)}
      style={style}
    >
      <div className="line1" style={{ backgroundColor: color }} />
      <div className="line2" style={{ backgroundColor: color }} />
    </div>
  );
};

CloseIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

CloseIcon.defaultProps = {
  color: 'white',
  style: {},
  className: ''
};

export default CloseIcon;