import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

const init = resources => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      load: 'languageOnly',
      nonExplicitWhitelist: false,
      fallbackLng: ['uk', 'en', 'ru'],
      debug: process.env.NODE_ENV === 'development',
      nsSeparator: ':::',
      keySeparator: '::',
      resources,

      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: true
      }
    });
};

export default init;

export const useLanguageChange = () => {
  const { go } = useHistory();

  const handleLanguageChange = useCallback(
    () => {
      go(0);
    },
    [go]
  );

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    }
    // eslint-disable-next-line
  }, []);
};