import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BreakpointsContext = createContext();

const breakpointsValues = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920
};

const defaultBreakpoints = {
  isXS320: false,
  isSM768: false,
  isMD1024: false,
  isLG1440: false,
  isXL1920: false
};

let timeout;

const BreakpointsProvider = props => {
  const { children } = props;
  const [breakpoints, setBreakpoints] = useState({ ...defaultBreakpoints });

  const updateBreakpoints = useCallback(() => {
    setBreakpoints({
      isXS320: window.innerWidth < breakpointsValues.sm,
      isSM768: window.innerWidth >= breakpointsValues.sm,
      isMD1024: window.innerWidth >= breakpointsValues.md,
      isLG1440: window.innerWidth >= breakpointsValues.lg,
      isXL1920: window.innerWidth >= breakpointsValues.xl,
    });
  }, [])

  const reportWindowSize = useCallback(
    () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        updateBreakpoints();
      }, 200);
    },
    [updateBreakpoints]
  )

  useEffect(() => {
    updateBreakpoints();
    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.addEventListener('resize', reportWindowSize);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BreakpointsContext.Provider value={{ ...breakpoints }}>
      {children}
    </BreakpointsContext.Provider>
  );
};

BreakpointsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BreakpointsProvider;

export const useBreakpoints = () => {
  const {
    isXS320,
    isSM768,
    isMD1024,
    isLG1440,
    isXL1920
  } = useContext(BreakpointsContext);

  return {
    isXS320,
    isSM768,
    isMD1024,
    isLG1440,
    isXL1920
  };
};