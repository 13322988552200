import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const colorMap = {
  ghost: '#a0a0a0',
  danger: '#ff00008a',
  success: '#088b00',
  white: '#ffffff',
  transparent: '#fff0'
};

const H1 = ({ children, ...rest }) => <h1 {...rest}>{children}</h1>;

H1.propTypes = {
  children: PropTypes.node.isRequired
};

const H2 = ({ children, ...rest }) => <h2 {...rest}>{children}</h2>;

H2.propTypes = {
  children: PropTypes.node.isRequired
};

const H3 = ({ children, ...rest }) => <h3 {...rest}>{children}</h3>;

H3.propTypes = {
  children: PropTypes.node.isRequired
};

const H4 = ({ children, ...rest }) => <h4 {...rest}>{children}</h4>;

H4.propTypes = {
  children: PropTypes.node.isRequired
};

const H5 = ({ children, ...rest }) => <h5 {...rest}>{children}</h5>;

H5.propTypes = {
  children: PropTypes.node.isRequired
};

const H6 = ({ children, ...rest }) => <h6 {...rest}>{children}</h6>;

H6.propTypes = {
  children: PropTypes.node
};

const P = ({ children, ...rest }) =>
  <p {...rest}>{children}</p>;

P.propTypes = {
  children: PropTypes.node
};

const Text = props => {
  return useMemo(() => {
    const {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      bold,
      italic,
      ghost,
      danger,
      success,
      style,
      center,
      right,
      upper,
      capitalize,
      lower,
      white,
      transparent,
      underline,
      lineThrough,
      newLine,
      className = '',
      ...rest
    } = props;

    let fontFace = 'Light';
    let fontStyle = { fontStyle: 'normal' };
    let textTransform = { textTransform: 'none' };
    let textDecoration = { textDecoration: 'none' };
    let textDisplay = { display: 'inline-block' };

    if (bold) {
      fontFace = 'Book';
    }

    if (italic) {
      fontStyle = { fontStyle: 'italic' };
    }

    if (upper) {
      textTransform = { textTransform: 'uppercase' };
    }

    if (capitalize) {
      textTransform = { textTransform: 'capitalize' };
    }

    if (lower) {
      textTransform = { textTransform: 'lowercase' };
    }

    if (underline) {
      textDecoration = { textDecoration: 'underline' };
    }

    if (lineThrough) {
      textDecoration = { textDecoration: 'line-through' };
    }

    if (newLine) {
      textDisplay = {};
    }

    const colorValue = ghost
      ? colorMap.ghost
      : (danger
        ? colorMap.danger
        : success
          ? colorMap.success
          : (white
            ? colorMap.white
            : (transparent
              ? colorMap.transparent : null)));

    const color = colorValue ? { color: colorValue } : {};
    const centered = center
      ? { textAlign: 'center' }
      : (right ? { textAlign: 'right' } : { textAlign: 'left' });

    const styleWithFont = {
      ...style,
      fontFamily: `Futura${fontFace}`,
      ...color,
      ...centered,
      ...fontStyle,
      ...textTransform,
      ...textDecoration,
      ...textDisplay
    };

    if (h1) {
      return <H1 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h1 ${className}`} />;
    } else if (h2) {
      return <H2 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h2 ${className}`} />;
    } else if (h3) {
      return <H3 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h3 ${className}`} />;
    } else if (h4) {
      return <H4 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h4 ${className}`} />;
    } else if (h5) {
      return <H5 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h5 ${className}`} />;
    } else if (h6) {
      return <H6 {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`h6 ${className}`} />;
    } else if (p) {
      return <P {...rest} style={{ margin: 0, lineHeight: 1.3, ...styleWithFont }} className={`p ${className}`} />
    }

    return null;
  }, [props]);
};

Text.propTypes = {
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  h6: PropTypes.bool,
  p: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  ghost: PropTypes.bool,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  underline: PropTypes.bool,
  style: PropTypes.object,
  center: PropTypes.bool,
  upper: PropTypes.bool,
  capitalize: PropTypes.bool,
  lineThrough: PropTypes.bool,
  right: PropTypes.bool,
  white: PropTypes.bool,
  newLine: PropTypes.bool
};

export default Text;
