export const SAGA_INIT = {
  isInit: true,
  isLoading: false,
  isSuccess: false,
  isFail: false
};

export const SAGA_LOADING = {
  isInit: false,
  isLoading: true,
  isSuccess: false,
  isFail: false
};

export const SAGA_SUCCESS = {
  isInit: false,
  isLoading: false,
  isSuccess: true,
  isFail: false
};

export const SAGA_FAIL = {
  isInit: false,
  isLoading: false,
  isSuccess: false,
  isFail: true
};