import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Text } from '../../../../../common';

const ForgotPasswordSentMessage = () => {
  const { t } = useTranslation();

  return (
    <Row align="center">
      <Col xs={6}>
        <Text h1 style={{ fontSize: 60 }}>💌</Text>
      </Col>
      <Col xs={18}>
        <Text p white>{t`The message successfully sent on your email. If you don\`t get any messages in 10 minutes, please check your SPAM folder. If it won\`t be there try to send request once again.`}</Text>
      </Col>
    </Row>
  );
};

ForgotPasswordSentMessage.propTypes = {};

export default ForgotPasswordSentMessage;