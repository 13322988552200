import React from 'react';
import PropTypes from 'prop-types';
import { Row as AntdRow } from 'antd';

const Row = props => {
  const {
    mb,
    mt,
    ml,
    mr,
    m,
    p,
    pb,
    pl,
    pr,
    pt,
    full,
    direction,
    justify,
    align,
    fullHeight,
    children,
    style,
    ...restProps
  } = props;

  let ownStyles = {
    ...(m ? { margin: m } : {}),
    ...(mb ? { marginBottom: mb } : {}),
    ...(mr ? { marginRight: mr } : {}),
    ...(ml ? { marginLeft: ml } : {}),
    ...(mt ? { marginTop: mt } : {}),
    ...(p ? { padding: p } : {}),
    ...(pb ? { paddingBottom: pb } : {}),
    ...(pr ? { paddingRight: pr } : {}),
    ...(pl ? { paddingLeft: pl } : {}),
    ...(pt ? { paddingTop: pt } : {}),
    ...(full ? { width: '100%' } : {}),
    ...(fullHeight ? { height: '100%' } : {}),
    ...(direction ? { flexDirection: direction } : {}),
    ...(justify ? { justifyContent: justify } : {}),
    ...(align ? { alignItems: align } : {}),
  };

  return (
    <AntdRow {...restProps} style={{ ...ownStyles, ...style }}>
      {children}
    </AntdRow>
  );
};

Row.propTypes = {
  mb: PropTypes.number, // margin-bottom
  mt: PropTypes.number, // margin-top
  ml: PropTypes.number, // margin-left
  mr: PropTypes.number, // margin-right
  m: PropTypes.number, // margin
  pb: PropTypes.number, // padding-bottom
  pt: PropTypes.number, // padding-top
  pl: PropTypes.number, // padding-left
  pr: PropTypes.number, // padding-right
  p: PropTypes.number, // padding
  children: PropTypes.node,
  style: PropTypes.object,
  full: PropTypes.bool,
  fullHeight: PropTypes.bool,
  justify: PropTypes.oneOf([
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'left',
    'right',
    'space-between',
    'space-around',
    'space-evenly'
  ]),
  align: PropTypes.oneOf(['center', 'start', 'end', 'flex-start', 'flex-end']),
  direction: PropTypes.oneOf(['column', 'row'])
};

Row.defaultProps = {
  style: {},
  mb: 0,
  mt: 0,
  ml: 0,
  mr: 0,
  m: 0,
  pb: 0,
  pt: 0,
  pl: 0,
  pr: 0,
  p: 0
}

export default Row;