export default {
  city: '',
  branch: '',
  deliveryAddress: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  countryCode: 'ua',
  dialCode: '380',
  isNovaPoshta: ''
};