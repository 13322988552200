import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetContext = createContext();

const HelmetProvider = props => {
  const { children } = props;
  const [title, setTitleState] = useState('GAZE');
  const [description, setDescriptionState] = useState('');

  const setTitle = useCallback(
    newTitle => setTitleState(newTitle),
    [setTitleState]
  );

  const setDescription = useCallback(
    newDescription => setDescriptionState(newDescription),
    [setDescriptionState]
  );

  return useMemo(() => (
    <HelmetContext.Provider value={{ setTitle, setDescription }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GAZE | {title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {children}
    </HelmetContext.Provider>
  ), [setTitle, setDescription, title, description, children]);
};

HelmetProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default HelmetProvider;

export const useHelmet = ({ title, description }) => {
  const { setTitle, setDescription } = useContext(HelmetContext);

  useEffect(() => {
    setTitle(title);
    setDescription(description);
  }, [description, setDescription, setTitle, title])
};