import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  sendRecoveryInfo: params =>
    axios.post('/api/client/sendrecoveryinfo', params)
};

function* sendRecoveryInfo({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { status } = yield call(Api.sendRecoveryInfo, params);
    if (status === 200) {
      onSuccess && onSuccess();
    }
  } catch (error) {
    onError && onError(error);
  }
}

export default sendRecoveryInfo;