import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

import { Col, Row, Text, useNotification } from '../../../../common';

import './styles.less';

const Switcher = props => {
  const { left, right, onChange, upper, isRightByDefault, disabled } = props;
  const [isLeft, setIsLeft] = useState(!isRightByDefault);
  const touched = useMemo(
    () => isLeft !== undefined,
    [isLeft]
  );
  const { showInfoMessage } = useNotification();
  const { t } = useTranslation();

  const handleToggle = useCallback(
    value => {
      if (disabled && value !== isLeft) {
        return showInfoMessage({
          title: t`Will ba available soon`,
          description: t`We are improving our payment process and apology for temporary inconvenience`,
          icon: '🥺'
        });
      }

      if (value !== isLeft) {
        setIsLeft(value);
        onChange(value);
      }
    },
    [disabled, isLeft, showInfoMessage, t, onChange]
  );

  const positionClass = useMemo(() => {
    if (!touched) return '';
    return isLeft ? 'left' : 'right';
  }, [isLeft, touched]);

  return (
    <Row full style={{ height: 30, position: 'relative' }}>
      <Col xs={12} className="switch-item" onClick={() => handleToggle(true)}>
        <Text h5 bold ghost={isLeft === false} upper={upper}>
          {left}
        </Text>
      </Col>
      <Col xs={12} className="switch-item" onClick={() => handleToggle(false)}>
        <Text h5 bold ghost={isLeft || !touched} upper={upper}>
          {right}
        </Text>
      </Col>
      <div className={clsx(
        'switch-item-highlighter',
        positionClass
      )} />
    </Row>
  );
};

Switcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  left: PropTypes.any.isRequired,
  right: PropTypes.any.isRequired,
  upper: PropTypes.bool,
  isRightByDefault: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Switcher;