import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStorageState } from 'react-storage-hooks';

import { CartContext } from './CartProvider';
import { updateItems, CartViews, setView } from '../reducers/cart';

const useCart = () => {
  const cart = useSelector(({ cart }) => cart);
  const dispatch = useDispatch();
  const { runCalculator } = useContext(CartContext);

  const { items, calculating, total, view } = cart;
  const [, setStoredCart] = useStorageState(sessionStorage, 'cart', cart);

  useEffect(() => {
    setStoredCart(cart);
  // eslint-disable-next-line
  }, [cart]);

  const updateCart = useCallback(
    newItems => {
      dispatch(updateItems(newItems));
      runCalculator(newItems);
    },
    [dispatch, runCalculator]
  );

  const setItemsView = useCallback(
    () => {
      dispatch(setView(CartViews.ITEMS));
    },
    [dispatch]
  );

  const setCheckoutView = useCallback(
    () => {
      dispatch(setView(CartViews.CHECKOUT));
    },
    [dispatch]
  );

  return {
    items,
    calculating,
    total,
    view,
    updateCart,
    CartViews,
    setCheckoutView,
    setItemsView
  };
};

export default useCart;