import React, { createContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useStorageState } from 'react-storage-hooks';

import { usePriceCalculator } from '../sagas';
import { setCart } from '../reducers/cart';
import { useUser } from '../../user';

export const CartContext = createContext();

const CartProvider = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const cart = useSelector(({ cart }) => cart);
  const [storedCart] = useStorageState(sessionStorage, 'cart', cart);
  const { calculate } = usePriceCalculator();
  const { userState: { user } } = useUser();
  const { items } = cart;

  useEffect(() => {
    if (storedCart) {
      dispatch(setCart(storedCart));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    runCalculator(items);
    // eslint-disable-next-line
  }, [user]);

  const runCalculator = useCallback(items => {
      if (items?.length) {
        calculate({
          clientId: user?.id,
          productIds: items.map(({ options, product }) => ({
            id: product.id,
            amount: options.length
          }))
        });
      }
    },
    [user, calculate]
  );

  return (
    <CartContext.Provider value={{ runCalculator }}>
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CartProvider;