import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.less';

const Page = props => {
  const { children, className, style, fullHeight } = props;

  return (
    <div
      className={clsx(
        'page-container',
        fullHeight && 'full-height',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  fullHeight: PropTypes.bool
};

Page.defaultProps = {
  className: '',
  style: {}
};

export default Page;