import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.less';

const Img = props => {
  const { src, alt, style, className, onClick, size, bw } = props;
  const [isClicking, setIsClicking] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      width={size}
      height={size}
      className={clsx(className, 'img', isClicking && 'active', bw && 'bw')}
      style={style}
      onClick={onClick}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onTouchStart={() => setIsClicking(true)}
      onTouchEnd={() => setIsClicking(false)}
    />
  );
};

Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  bw: PropTypes.bool
};

Img.defaultProps = {
  style: {},
  className: '',
  onClick: () => {},
  size: 25
};

export default Img;