import React from 'react';
import PropTypes from 'prop-types';
import { Col as AntdCol } from 'antd';

const Col = props => {
  const { mb, mt, ml, mr, m, p, pb, pl, pr, pt, children, style, ...restProps } = props;

  let ownStyles = {
    ...(m ? { margin: m } : {}),
    ...(mb ? { marginBottom: mb } : {}),
    ...(mt ? { marginTop: mt } : {}),
    ...(mr ? { marginRight: mr } : {}),
    ...(ml ? { marginLeft: ml } : {}),
    ...(p ? { padding: p } : {}),
    ...(pb ? { paddingBottom: pb } : {}),
    ...(pt ? { paddingTop: pt } : {}),
    ...(pr ? { paddingRight: pr } : {}),
    ...(pl ? { paddingLeft: pl } : {}),
  };

  return (
    <AntdCol {...restProps} style={{ ...ownStyles, ...style }}>
      {children}
    </AntdCol>
  );
};

Col.propTypes = {
  mb: PropTypes.number, // margin-bottom
  mt: PropTypes.number, // margin-top
  ml: PropTypes.number, // margin-left
  mr: PropTypes.number, // margin-right
  m: PropTypes.number, // margin
  pb: PropTypes.number, // padding-bottom
  pt: PropTypes.number, // padding-top
  pl: PropTypes.number, // padding-left
  pr: PropTypes.number, // padding-right
  p: PropTypes.number, // padding
  children: PropTypes.node,
  style: PropTypes.object
};

Col.defaultProps = {
  style: {},
  mb: 0,
  mt: 0,
  ml: 0,
  mr: 0,
  m: 0,
  pb: 0,
  pt: 0,
  pl: 0,
  pr: 0,
  p: 0
}

export default Col;