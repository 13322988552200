import { call } from 'redux-saga/effects';
import axios from 'axios';

const { REACT_APP_NEW_POST_API_URL, REACT_APP_NEW_POST_API_KEY } = process.env;

const Api = {
  loadNPCities: CityName =>
    axios.post(`${REACT_APP_NEW_POST_API_URL}/json/`, {
      apiKey: REACT_APP_NEW_POST_API_KEY,
      modelName: 'Address',
      calledMethod: 'searchSettlements',
      methodProperties: { CityName, Limit: 20 }
    })
};

function* loadNPCities({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();

  try {
    const { status, data } = yield call(Api.loadNPCities, params);
    if (status === 200) {
      onSuccess && onSuccess(data);
    } else {
      onError && onError(data);
    }
  } catch (error) {
    onError && onError(error);
  }
}

export default loadNPCities;