import { object, string, mixed } from 'yup';

export default object().shape({
  city: object().when(
    'isNovaPoshta', {
      is: true,
      then: object().required(),
      otherwise: object()
    }),
  branch: object().test(
    'is-branch-required',
    'Branch is required',
    (value, { parent }) => {
      const { isNovaPoshta, deliveryAddress } = parent;
      return isNovaPoshta && !deliveryAddress
        ? !!value
        : true;
    }
  ),
  deliveryAddress: string().test(
    'is-delivery-address-required',
    'Delivery address is required',
    (value, { parent }) => {
      const { isNovaPoshta, branch } = parent;
      return isNovaPoshta && !branch
        ? !!value
        : true;
    }
  ),
  firstName: string().required(),
  lastName: string().required(),
  phoneNumber: mixed().test(
    'is-ukrainian-number',
    'Not valid phone number',
    (value, { parent }) => {
      if (parent.countryCode !== 'ua') return true;
      return value && value.length === 9;
    }
  ).required(),
  countryCode: string().required(),
  dialCode: string().required()
});