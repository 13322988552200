import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trigger } from '../../client/common/reducers/bottomBar';

const useBottomBar = () => {
  const { bottomBarOpened } = useSelector(({ bottomBar }) => bottomBar);
  const dispatch = useDispatch();

  const triggerBottomBar = useCallback(
    isOpen => {
      dispatch(trigger(isOpen));
    },
    [dispatch]
  );

  return { bottomBarOpened, triggerBottomBar };
};

export default useBottomBar;