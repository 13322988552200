import { useCallback, useState } from 'react';
import { takeEvery } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import idx from 'idx';
import { useStorageState } from 'react-storage-hooks';
import { useHistory } from 'react-router-dom';

import calculatePrice from './calculatePrice';
import loadNPCities from './loadNPCities';
import loadNPBranches from './loadNPBranches';
import createOrder from './createOrder';
import createNoPayOrder from './createNoPayOrder';
import loadCatalog from './loadCatalog';
import { setCalculating, setTotal } from '../reducers/cart';
import { useNotification } from '../../../common';
import { updateGazePartition, updateWorldPartition } from '../reducers/tree';

const CALCULATE_PRICE = 'CALCULATE_PRICE';
const LOAD_NP_CITY = 'LOAD_NP_CITY';
const LOAD_NP_BRANCH = 'LOAD_NP_BRANCH';
const CREATE_ORDER = 'CREATE_ORDER';
const CREATE_NO_PAY_ORDER = 'CREATE_NO_PAY_ORDER';
const LOAD_CATALOG = 'LOAD_CATALOG';

export default [
  takeEvery(CALCULATE_PRICE, calculatePrice),
  takeEvery(LOAD_NP_CITY, loadNPCities),
  takeEvery(LOAD_NP_BRANCH, loadNPBranches),
  takeEvery(CREATE_ORDER, createOrder),
  takeEvery(CREATE_NO_PAY_ORDER, createNoPayOrder),
  takeEvery(LOAD_CATALOG, loadCatalog),
];

export const usePriceCalculator = () => {
  const dispatch = useDispatch();

  const calculate = useCallback(
    params => {
      dispatch({
        type: CALCULATE_PRICE,
        payload: {
          params,
          onStart: () => {
            dispatch(setCalculating(true));
          },
          onSuccess: ({ price }) => {
            dispatch(setTotal(price.sum));
          },
          onError: () => {
            dispatch(setCalculating(false));
          }
        }
      });
    },
    [dispatch]
  );

  return { calculate };
};

export const useNP = () => {
  const dispatch = useDispatch();
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(false);
  const [branches, setBranches] = useState([]);

  const findCity = useCallback(
    params => {
      dispatch({
        type: LOAD_NP_CITY,
        payload: {
          params,
          onStart: () => {
            setCitiesLoading(true);
          },
          onSuccess: result => {
            const { data } = result;
            setCities(idx(data[0], _ => _.Addresses) || []);
            setCitiesLoading(false);
          },
          onError: () => {
            setCitiesLoading(false);
          },
        }
      });
    },
    [dispatch, setCities, setCitiesLoading]
  );

  const clearCities = useCallback(
    () => setCities([]),
    [setCities]
  );

  const findBranches = useCallback(
    params => {
      dispatch({
        type: LOAD_NP_BRANCH,
        payload: {
          params,
          onStart: () => {
            setBranchesLoading(true);
          },
          onSuccess: result => {
            const { data } = result;
            setBranches(idx(data, _ => _.data) || []);
            setBranchesLoading(false);
          },
          onError: () => {
            setBranchesLoading(false);
          },
        }
      });
    },
    [dispatch, setBranches, setBranchesLoading]
  );

  const clearBranches = useCallback(
    () => setBranches([]),
    [setBranches]
  );

  return {
    findCity,
    cities,
    citiesLoading,
    findBranches,
    branches,
    branchesLoading,
    clearCities,
    clearBranches
  };
};

export const useOrder = () => {
  const dispatch = useDispatch();
  const [orderLoading, setOrderLoading] = useState(false);
  const [, setOrderReference] = useStorageState(sessionStorage, 'orderReference');
  const { push } = useHistory();
  const { showErrorMessage } = useNotification();

  const createLiqPayOrder = useCallback(
    params => {
      dispatch({
        type: CREATE_ORDER,
        payload: {
          params,
          onStart: () => {
            setOrderLoading(true);
          },
          onSuccess: result => {
            const { form, orderReference } = result;
            setOrderReference(orderReference);
            const liqPayFormContainer = document.getElementById('liqpay-form');
            if (liqPayFormContainer) {
              liqPayFormContainer.innerHTML = '';
              liqPayFormContainer.innerHTML = form;
              liqPayFormContainer.firstChild.submit();
            }
            setOrderLoading(false);
          },
          onError: () => {
            setOrderLoading(false);
          }
        }
      })
    },
    [dispatch, setOrderReference]
  );

  const createNoPayOrder = useCallback(
    params => {
      dispatch({
        type: CREATE_NO_PAY_ORDER,
        payload: {
          params,
          onStart: () => {
            setOrderLoading(true);
          },
          onSuccess: result => {
            const { orderReference } = result;
            setOrderReference(orderReference);
            setOrderLoading(false);
            push('/complete');
          },
          onError: error => {
            setOrderLoading(false);
            if (idx(error, _ => _.request.responseText)) {
              showErrorMessage({
                title: error.request.responseText,
                icon: '😿'
              });
            }
          }
        }
      })
    },
    [push, dispatch, setOrderReference, showErrorMessage]
  )

  return { createLiqPayOrder, createNoPayOrder, orderLoading };
};

export const useCatalog = () => {
  const dispatch = useDispatch();
  const { gazePartition, worldPartition } = useSelector(({ tree }) => tree);

  const loadGazeCatalog = useCallback(() => {
    dispatch({
      type: LOAD_CATALOG,
      payload: {
        partitionId: 1,
        onSuccess: ({ data }) => dispatch(updateGazePartition(data.catalog)),
        onError: () => {}
      }
    });
  }, [dispatch]);

  const loadWorldCatalog = useCallback(() => {
    dispatch({
      type: LOAD_CATALOG,
      payload: {
        partitionId: 2,
        onSuccess: ({ data }) => dispatch(updateWorldPartition(data.catalog)),
        onError: () => {}
      }
    });
  }, [dispatch]);

  return { loadGazeCatalog, loadWorldCatalog, gazePartition, worldPartition };
};