import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../..';

const ErrorMessage = props => {
  const { children } = props;

  return (
    <Text h5 white style={{ backgroundColor: '#ff00008a', padding: '0 5px' }}>
      {children}
    </Text>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorMessage;