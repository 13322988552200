import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  calculatePrice: params =>
    axios.post('/api/product/price/calculate?identity=client', params)
};

function* calculatePrice({ payload: { params, onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { status, data } = yield call(Api.calculatePrice, params);
    if (status === 200) {
      onSuccess && onSuccess(data);
    }
  } catch (error) {
    onError && onError(error);
  }
}

export default calculatePrice;