import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard as CopyToClipboardLib } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '..';

import './styles.less';


const CopyToClipboard = props => {
  const { text, onCopy, style, className } = props;
  const { t } = useTranslation();
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  const [copyKey, setCopyKey] = useState(0);
  const ref = useRef();

  const timestamp = useMemo(() => Date.now(), []);

  const handleOnCopy = useCallback(
    () => {
      clearTimeout(ref.current);
      setIsCopyVisible(true);
      setCopyKey(copyKey + 1);
      onCopy();
      ref.current = setTimeout(() => {
        setIsCopyVisible(false);
      }, 1000);
    },
    [onCopy, setIsCopyVisible, setCopyKey, copyKey]
  );

  return (
    <CopyToClipboardLib text={text} onCopy={handleOnCopy}>
      <div style={style} className={className}>
        <div className="clipboard-container">
          <Button shape="circle" icon={<CopyOutlined />} />
          {isCopyVisible && (
            <Text key={`${timestamp}-${copyKey}`} p white className="copied-text">
              {t`Copied`}
            </Text>
          )}
        </div>
      </div>
    </CopyToClipboardLib>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  onCopy: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

CopyToClipboard.defaultProps = {
  style: {},
  className: ''
};

export default CopyToClipboard;