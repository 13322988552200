import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import FormSearcher from '../../../../../../../common/components/FormAdapters/FormSearcher';
import { useNP } from '../../../../../sagas';

const CityField = props => {
  const { setFieldValue } = props;
  const { t } = useTranslation();
  const { findCity, cities, citiesLoading, clearCities } = useNP();

  const cityOptions = useMemo(
    () => {
      return cities.map(({ Present: label, Ref: value }) => ({ label, value }))
    },
    [cities]
  );

  const handleOnCitySearch = useCallback(
    value => {
      findCity(value);
    },
    [findCity]
  );

  const handleOnCitySelect = useCallback(
    ({ value }) => {
      setFieldValue(
        'city',
        cityOptions.find(_ => _.value === value)
      );
    },
    [setFieldValue, cityOptions]
  );

  return useMemo(() => (
    <Field
      white
      id="city-field"
      placeholder={t`City`}
      component={FormSearcher}
      name="city"
      options={cityOptions}
      f="city"
      inputStyle={{
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: 16
      }}
      loading={citiesLoading}
      onSearch={handleOnCitySearch}
      onSelect={handleOnCitySelect}
      onClear={clearCities}
    />
  ), [clearCities, t, cityOptions, citiesLoading, handleOnCitySearch, handleOnCitySelect]);
};

CityField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default CityField;