import { call } from 'redux-saga/effects';
import axios from 'axios';

const { REACT_APP_NEW_POST_API_URL, REACT_APP_NEW_POST_API_KEY } = process.env;

const Api = {
  loadNPBranches: SettlementRef =>
    axios.post(`${REACT_APP_NEW_POST_API_URL}/json/`, {
      apiKey: REACT_APP_NEW_POST_API_KEY,
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: { SettlementRef }
    })
};

function* loadNPBranches({ payload: { params, onSuccess, onError } }) {
  try {
    const result = yield call(Api.loadNPBranches, params);
    if (result.status === 200) {
      onSuccess(result);
    } else {
      onError(result);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

export default loadNPBranches;