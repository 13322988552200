import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Input, Upload } from 'antd';
import { LoadingOutlined, DeleteOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
// import ReactPhoneInput from 'react-phone-input-2';

import FormInput from './FormInput';
import FormInputNumber from './FormInputNumber';
import FormTextArea from './FormTextArea';
import FormSelect from './FormSelect';
import FormPhoneInput from './FormPhoneInput';

import { Text } from '..';

// import 'react-phone-input-2/lib/style.css';

const { Dragger } = Upload;

const Img = () => <div>MOCK_IMG</div>;

const getBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const PreviewImage = ({ src, onDelete }) => {
  return (
    <div className="preview-image">
      <Img cover src={src} />
      <div className="preview-image-delete d-flex justify-content-center align-items-center">
        <DeleteOutlined
          className="pointer"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

const ErrorMessage = ({ children }) => (
  <Text h5 white style={{ backgroundColor: '#ff00008a', padding: '0 5px' }}>
    {children}
  </Text>
);

const FormUploadImage = ({ field, form, className = '', ...props }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (fileList.length) {
      form.setFieldValue(field.name, fileList[0].originFileObj);
    }
  // eslint-disable-next-line
  }, [fileList.length]);

  useEffect(() => {
    if (field.value) {
      setPreviewImage(field.value);
    }
  // eslint-disable-next-line
  }, [field.value]);

  const onChange = async ({ file, fileList }) => {
    setPreviewImage(await getBase64(file.originFileObj));
    setFileList(fileList);
  };

  const onDelete = () => {
    setPreviewImage(null);
  };

  return (
    <>
      {!previewImage && (
        <Dragger
          {...field}
          {...props}
          name="file"
          showUploadList={false}
          multiple={false}
          onChange={onChange}
          className={`${className} mb-1`}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
            band files
          </p>
        </Dragger>
      )}
      {previewImage && <PreviewImage src={previewImage} onDelete={onDelete} />}
      {form.errors[props.f] && <ErrorMessage>{form.errors[props.f]}</ErrorMessage>}
    </>
  );
};

const FormSearcher = props => {

  const {
    field,
    form,
    onSearch,
    onSelect,
    loading,
    placeholder,
    inputStyle = {},
    items,
    ...restProps
  } = props;

  const [input, setInput] = useState({ value: null, search: false });
  const ref = useRef(null);
  const icon = loading
    ? <LoadingOutlined />
    : (form.values[field.name] ? <DeleteOutlined /> : <SearchOutlined />);

  useEffect(() => {
    if (input.search) {
      const timeoutId = setTimeout(() => onSearch(input.value), 500);
      return () => clearTimeout(timeoutId);
    }
  // eslint-disable-next-line
  }, [input]);

  const handleChange = value => {
    setInput({ value, search: true });
    form.setFieldValue(field.name, undefined);
  };

  const handleOnSelect = key => {
    form.setFieldValue(field.name, key);
    const item = items.find(_ => _.key === key);
    onSelect(item);
    setInput({ value: item.value, search: false });
  };

  const handleBlur = () =>
    form.setFieldTouched(field.name, true);

  const renderItems = () => {
    return items && items.map(({ key, value }) => (
      <AutoComplete.Option key={key} value={key}>{value}</AutoComplete.Option>
    ));
  };

  // const onIconClick = e => {
  //   e.stopPropagation();
  //   if (icon === 'delete') {
  //     setInput({ value: null, search: false });
  //     form.setFieldValue(field.name, null);
  //   }
  // };

  return (
    <AutoComplete
      {...field}
      {...restProps}
      ref={ref}
      onSelect={handleOnSelect}
      onBlur={handleBlur}
      options={renderItems()}
      onSearch={handleChange}
      disabled={loading}
      value={input.value}
    >
      <Input
        // suffix={
        //   <Icon type={icon} style={{ color: 'rgba(0,0,0,.45)' }} onClick={onIconClick} />
        // }
        suffix={icon}
        placeholder={placeholder}
        style={inputStyle}
      />
    </AutoComplete>
  );
};

// const FormPhoneInput = ({ field, form, style = {}, ...props }) => {
//   const [pValue, setPValue] = useState('');
//   const { setFieldValue, values, setFieldTouched, touched } = form;
//
//   useEffect(() => {
//     setFieldValue('countryCode', 'ua');
//     setFieldValue('dialCode', 380);
//     if (values[props.f]) {
//       setPValue(values[props.f]);
//     }
//   // eslint-disable-next-line
//   }, []);
//
//   const handleOnChange = (value, { dialCode, countryCode }) => {
//     const { onChange } = field;
//     setPValue(value);
//     onChange(value);
//     const withNoDialCode = value.replace(/[^0-9]+/g,'').slice(dialCode.length);
//     setFieldValue('phoneNumber', withNoDialCode);
//     setFieldValue('countryCode', countryCode);
//     setFieldValue('dialCode', +dialCode);
//   };
//
//   const handleOnBlur = useCallback(
//     fieldName => {
//       setFieldTouched(fieldName);
//       if (props.onBlur) {
//         props.onBlur();
//       }
//     },
//     [setFieldTouched, props]
//   );
//
//   return (
//     <>
//       <ReactPhoneInput
//         countryCodeEditable={false}
//         inputExtraProps={{ required: true, ...props }}
//         inputClass="phone-input"
//         containerStyle={{ marginBottom: '.25rem' }}
//         inputStyle={{ ...style, borderRadius: 1, width: '100%', border: '1px solid #d9d9d9', fontFamily: 'FuturaLight' }}
//         country={'ua'}
//         regions={['america', 'europe']}
//         value={pValue}
//         onChange={handleOnChange}
//         onBlur={() => handleOnBlur(props.f)}
//       />
//
//       <div style={{ position: 'relative', height: 20, width: '100%' }}>
//         {(touched[props.f] && form.errors[props.f]) && (
//           <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{form.errors[props.f]}</ErrorMessage>
//         )}
//       </div>
//     </>
//   );
// };

export { FormInput, FormInputNumber, FormTextArea, FormSelect, FormPhoneInput, FormSearcher, FormUploadImage };