import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import clsx from 'clsx';

import { Button, Col, Row, Text, transformCurrency } from '../../../../../../common';
import CityField from './CityField';
import BranchField from './BranchField';
import FormInput from '../../../../../../common/components/FormAdapters/FormInput';
import FormPhoneInput from '../../../../../../common/components/FormAdapters/FormPhoneInput';
import Switcher from '../../../Switcher';
import { useCart } from '../../../../providers';
import useGTM, { Events } from '../../../../../../common/hooks/useGTM';

import validationSchema from './validationSchema';
import initialValues from './initialValues';
import { useOrder } from '../../../../sagas';
import { useUser } from '../../../../../user';

import './styles.less';

const NovaPoshtaDeliveryForm = () => {
  const { t } = useTranslation();
  const { total, items } = useCart();
  const { createLiqPayOrder, createNoPayOrder, orderLoading } = useOrder();
  const { userState } = useUser();
  const { user } = userState;
  const {
    id: clientId,
    dialCode: uDialCode,
    countryCode: uCountryCode,
    phoneNumber: uPhoneNumber,
    firstName: uFirstName,
    lastName: uLastName
  } = user || {};

  const [isNovaPoshta, setIsNovaPoshta] = useState(true);
  const [isAddressDelivery, setIsAddressDelivery] = useState(false);
  const [isPayNow, ] = useState(false);
  const { pushEvent } = useGTM();

  const handleOnAddressDelivery = useCallback(value => {
    setIsAddressDelivery(value);
  }, [setIsAddressDelivery]);

  const handlePaymentSwitch = useCallback(value => {
    // setIsPayNow(value);

    if (value === false) {
      pushEvent(Events.cartCategory.payLaterOptionClick());
    }
  }, [pushEvent]);

  const handleOnSubmit = useCallback(
    values => {
      const {
        city,
        branch,
        firstName,
        lastName,
        phoneNumber,
        deliveryAddress: address
      } = values;

      let delivery = { deliveryType: 'SELF_SERVE' };

      if (isNovaPoshta) {
        delivery = Object
          .entries({
            deliveryType: 'NEW_POST',
            city: city.label,
            branchNumber: branch.label,
            address
          })
          .reduce((acc, [key, value]) => {
            return value
              ? { ...acc, [key]: value }
              : acc;
          }, {});
      }

      const params = {
        clientId,
        delivery,
        recipient: {
          firstName,
          lastName,
          phoneNumber
        },
        options: items.map(({ product, options }) =>
          options.map(({ colorCode: color, size }) => ({ productId: product.id, color, size }))
        ).flat()
      };

      if (isPayNow) {
        createLiqPayOrder(params);
      } else {
        createNoPayOrder(params);
      }

      pushEvent(
        Events
          .cartCategory
          .payClick({ label: isPayNow ? 'pay_now' : 'pay_later' })
      );
    },
    [
      isNovaPoshta,
      clientId,
      items,
      isPayNow,
      pushEvent,
      createLiqPayOrder,
      createNoPayOrder
    ]
  );

  return useMemo(() => {
    return (
      <>
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={{
            ...initialValues,
            dialCode: uDialCode || '',
            countryCode: uCountryCode || '',
            phoneNumber: uPhoneNumber || '',
            firstName: uFirstName || '',
            lastName: uLastName || ''
          }}
          enableReinitialize
        >
          {({ setFieldValue, values, isValid }) => {
            if (isNovaPoshta !== values.isNovaPoshta) {
              setFieldValue('isNovaPoshta', isNovaPoshta);
            }

            if (isAddressDelivery && values.branch) {
              setFieldValue('branch', '');
            }

            if (!isAddressDelivery && values.deliveryAddress) {
              setFieldValue('deliveryAddress', '');
            }

            return (
              <Form style={{ width: '100%' }}>
                <Col xs={24} mb={30}>
                  <Switcher
                    onChange={value => setIsNovaPoshta(value)}
                    left={t`Nova Poshta`}
                    right={t`Pickup`}
                  />
                </Col>
                <div className={
                  clsx(
                    'nova-poshta-fields',
                    !isNovaPoshta && 'hidden'
                  )
                }>
                  <Row mb={10}>
                    <CityField
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Row>
                  <Row mb={10}>
                    <Row align="center" mb={5}>
                      <Switch
                        checked={isAddressDelivery}
                        size="small"
                        style={{ marginRight: 10 }}
                        onChange={handleOnAddressDelivery}
                      />
                      <Text p white>{t`Address delivery`}</Text>
                    </Row>
                    <div style={{
                      width: '100%',
                      display: isAddressDelivery ? 'none' : 'block'
                    }}>
                      <BranchField
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>
                    <div style={{
                      width: '100%',
                      display: !isAddressDelivery ? 'none' : 'block'
                    }}>
                      <Field
                        placeholder={t`Delivery address`}
                        component={FormInput}
                        name="deliveryAddress"
                        f="deliveryAddress"
                        style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                      />
                    </div>
                  </Row>
                </div>
                <Row mb={10}>
                  <Field
                    placeholder={t`Name`}
                    component={FormInput}
                    name="firstName"
                    f="firstName"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row mb={10}>
                  <Field
                    placeholder={t`Surname`}
                    component={FormInput}
                    name="lastName"
                    f="lastName"
                    style={{ backgroundColor: 'transparent', color: 'white', fontSize: 16 }}
                  />
                </Row>
                <Row mb={50}>
                  <Field
                    placeholder={t`Phone`}
                    component={FormPhoneInput}
                    name="phoneNumber"
                    type="phoneNumber"
                    f="phoneNumber"
                    inputStyle={{
                      backgroundColor: 'transparent',
                      color: 'white',
                      fontSize: 16
                    }}
                  />
                </Row>
                <Col mb={20}>
                  <Switcher
                    onChange={handlePaymentSwitch}
                    left={t`Pay now`}
                    right={t`Pay later`}
                    isRightByDefault
                    disabled
                  />
                  <Row className={
                    clsx(
                      'nova-poshta-fields',
                      isPayNow && 'hidden'
                    )
                  } mt={20}>
                    <Col xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Text h1>
                        💁🏻‍♀️️
                      </Text>
                    </Col>
                    <Col xs={20}>
                      <Text p white>
                        {t`Our manager will contact you after checkout for clarification on what payment type works best for you`}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Button
                    htmlType="submit"
                    fullWidth
                    disabled={!isValid || orderLoading}
                    loading={orderLoading}
                  >
                    {
                      isPayNow
                        ? t('Pay', { total: transformCurrency(total, 'UAH') })
                        : t`Complete order`
                    }
                  </Button>
                </Col>
              </Form>
            );
          }}
        </Formik>
        <div id="liqpay-form" style={{ visibility: 'hidden' }} />
      </>
    );
  }, [
    handlePaymentSwitch,
    handleOnSubmit,
    uDialCode,
    uCountryCode,
    uPhoneNumber,
    uFirstName,
    uLastName,
    isNovaPoshta,
    isAddressDelivery,
    t,
    handleOnAddressDelivery,
    isPayNow,
    orderLoading,
    total
  ]);
};

NovaPoshtaDeliveryForm.propTypes = {};

export default NovaPoshtaDeliveryForm;