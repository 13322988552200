import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bottomBarOpened: null
};

const bottomBarSlice = createSlice({
  name: 'bottomBar',
  initialState,
  reducers: {
    trigger(state, { payload }) {
      return {
        ...state,
        bottomBarOpened: payload
      }
    },
  }
});

export const { trigger } = bottomBarSlice.actions;

export default bottomBarSlice.reducer;