import { useCallback } from 'react';

export const Events = {
  landingCategory: {
    loginClickEvent: () => ({
      event: 'login_click',
      category: 'landing',
      action: 'button_click',
      label: 'login'
    }),
    profileClickEvent: () => ({
      event: 'profile_click',
      category: 'landing',
      action: 'button_click',
      label: 'profile'
    }),
    productsClickEvent: () => ({
      event: 'products_click',
      category: 'landing',
      action: 'button_click',
      label: 'products'
    })
  },
  productsCategory: {
    availableProductsFilterClick: ({ label }) => ({
      event: 'filter_click',
      category: 'products',
      action: 'button_click',
      label
    }),
    productDetailsClick: ({ label }) => ({
      event: 'products_details_click',
      category: 'products',
      action: 'button_click',
      label
    }),
    addToCartClick: ({ label }) => ({
      event: 'add_to_cart_click',
      category: 'products',
      action: 'button_click',
      label
    }),
  },
  cartCategory: {
    checkoutClick: () => ({
      event: 'checkout_click',
      category: 'cart',
      action: 'button_click',
      label: 'checkout'
    }),
    payLaterOptionClick: () => ({
      event: 'pay_later_option_click',
      category: 'cart',
      action: 'button_click',
      label: 'pay_later'
    }),
    payClick: ({ label }) => ({
      event: 'pay_click',
      category: 'cart',
      action: 'button_click',
      label
    })
  }
};

const useGTM = () => {

  const pushEvent = useCallback(
    ({ event, category, action, label, ...customDimensions }) => {
      window.dataLayer.push({
        event,
        category,
        action,
        label,
        ...customDimensions
      })
    },
    []
  );

  return { pushEvent };
};

export default useGTM;