import React, { useState } from 'react';
import clsx from 'clsx';
import { Col } from 'antd';

import Login from '../Login';
import Registration from '../Registration';
import ForgotPassword from '../ForgotPassword';

import './styles.less';

const Authorization = () => {
  const [view, setView] = useState('login');

  return (
    <div className="login-menu">
      <Col className="form-content">
        <div className={clsx('views', view)}>
          <Login
            onJoinClick={() => setView('registration')}
            onForgotPasswordClick={() => setView('forgot-password')}
          />
          <Registration onBackClick={() => setView('login')} />
          <ForgotPassword onBackClick={() => setView('login')} />
        </div>
      </Col>
    </div>
  );
};

Authorization.propTypes = {};

export default Authorization;