import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  identify: () =>
    axios.get('/api/client/identity')
};

function* identify({ payload: { onStart, onSuccess, onError } }) {
  onStart && onStart();
  try {
    const { data, status } = yield call(Api.identify);
    if (status === 200) {
      onSuccess && onSuccess(data);
    }
  } catch (error) {
    onError && onError(error);
  }
}

export default identify;