import { useSelector } from 'react-redux';

import { setUserSuccess, setUserReset, setUserFail, setUserLoading } from '../reducers/user';

const useUser = () => {
  const userState = useSelector(({ user }) => user);

  return { userState, setUserSuccess, setUserReset, setUserFail, setUserLoading };
};

export default useUser;