import { call } from 'redux-saga/effects';
import axios from 'axios';

const Api = {
  loadCatalog: partitionId =>
    axios.get(`/api/section/tree/${partitionId}`)
};

function* loadCatalog({ payload: { partitionId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.loadCatalog, partitionId);
    if (result.status === 200) {
      onSuccess(result);
    } else {
      onError(result);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

export default loadCatalog;