import React from 'react';

class Module {
  constructor(...modules) {
    this.routes = modules.reduce((acc, { routes }) => routes ? [...acc, ...routes] : acc, []);
    this.sagas = modules.reduce((acc, { sagas }) => sagas ? [...acc, ...sagas] : acc, []);
    this.reducers = modules.reduce((acc, { reducers }) => reducers ? { ...acc, ...reducers } : acc, {});
    this.sideBarMenu = modules.reduce((acc, { sideBarMenu }) => sideBarMenu ? acc.concat(sideBarMenu) : acc, []);
    this.locales = modules.reduce(
      (acc, { locales }) => locales
        ? Object
          .keys(locales)
          .reduce((ln, key) => ({ ...ln, [key]: { ...ln[key], ...locales[key] } }), acc)
        : acc,
      {});
  }

  getRoutes = () => {
    return this.routes.map((component, idx) => React.cloneElement(component, { key: `${idx}${this.routes.length}` }));
  };

  getSagas = () => {
    return this.sagas;
  };

  getReducers = () => {
    return this.reducers;
  };

  getLocales = () => {
    return this.locales;
  };

  getSideBarMenu = () => {
    return this.sideBarMenu
  };

}

export default Module;