import React, { createContext, useCallback, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationType = {
  Info: 'info',
  Error: 'error',
  Success: 'success'
};

const iconMapper = {
  [NotificationType.Info]: '💁🏽‍♀️',
  [NotificationType.Success]: '🙌🏽',
  [NotificationType.Error]: '🤷🏽‍♀️'
};

const NotificationProvider = props => {
  const { children } = props;
  const [{
    title,
    description,
    duration = 5000,
    type = NotificationType.Info,
    icon = ''
  }, setOptions] = useState({});

  const showNotification = useCallback(
    ({ icon, ...newOptions }) => {
      setOptions({
        ...newOptions,
        icon: icon || iconMapper[newOptions.type]
      });
    },
    []
  )

  return (
    <NotificationContext.Provider value={{
      showNotification,
      NotificationType,
      options: { title, description, duration, type, icon }
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {};

export default NotificationProvider;