import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trigger } from '../reducers/menu';

const useMenu = () => {
  const { menuOpened } = useSelector(({ menu }) => menu);
  const dispatch = useDispatch();

  const triggerMenu = useCallback(
    name => {
      dispatch(trigger(name))
    },
    [dispatch]
  );

  return { menuOpened, triggerMenu };
};

export default useMenu;