import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '..';

import './styles.less';

const FilterButton = props => {
  const { children, checked, className, ...restProps } = props;

  return (
    <Button
      transparent
      {...restProps}
      className={
        clsx(
          className,
          checked && 'filter-button-checked'
        )
      }
    >
      {children}
    </Button>
  );
};

FilterButton.propTypes = {
  children: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string
};

FilterButton.defaultProps = {
  className: ''
};

export default FilterButton;