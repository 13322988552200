import React, { useEffect, useState, cloneElement, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';

import { CloseIcon, Row, Col, Text } from '..';

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      isUnmounted.current = false;
    };
  }, []);

  const closeModal = useCallback(() => {
    if (isUnmounted.current) return;
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const showModal = useCallback(() => {
    if (isUnmounted.current) return;
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return {
    isModalOpen,
    closeModal,
    showModal
  };
};

const Modal = props => {
  const { btnComponent, children, title } = props;
  const { showModal, isModalOpen, closeModal } = useModal();

  const handleShowModal = useCallback(
    e => {
      e.stopPropagation();
      showModal();
    },
    [showModal]
  );

  const handleOnCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const button = useMemo(
    () => cloneElement(btnComponent, { onClick: handleShowModal }),
    [btnComponent, handleShowModal]
  );

  const titleComponent = useMemo(() => (
    <Row>
      <Col xs={22}>
        <Text h3 upper>{title}</Text>
      </Col>
      <Col xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CloseIcon color="#000000" onClick={handleOnCancel} />
      </Col>
    </Row>
  ), [title, handleOnCancel]);

  return useMemo(() => (
    <>
      {button}
      <AntdModal
        visible={isModalOpen}
        footer={null}
        closable={false}
        title={titleComponent}
        destroyOnClose
        onCancel={handleOnCancel}
      >
        {children}
      </AntdModal>
    </>
  ), [
    button,
    isModalOpen,
    titleComponent,
    handleOnCancel,
    children
  ]);
}

Modal.propTypes = {
  btnComponent: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.any
};

export default Modal;