import React from 'react';

import { LoaderText } from '../../../client/common';

const Fallback = () => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white'
    }}>
      <LoaderText />
    </div>
  );
};

Fallback.propTypes = {};

export default Fallback;