import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import './styles.less';

const menu = document.getElementById('menu');

const AppMenu = props => {
  const { children, allowScroll } = props;
  const appMenu = document.createElement('div');

  useEffect(() => {
    const scrollPosition = window.scrollY;

    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    return () => {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');

      window.scrollTo(0, scrollPosition);
    };
  }, []);

  useEffect(() => {
    appMenu.classList.add('app-menu');
    appMenu.classList.add('opened');
    allowScroll && appMenu.classList.add('allow-scroll');
    menu.appendChild(appMenu);

    return () => {
      menu.removeChild(appMenu);
    };
  // eslint-disable-next-line
  }, []);

  return ReactDOM.createPortal(
    children,
    appMenu
  );
};

AppMenu.propTypes = {
  children: PropTypes.node.isRequired,
  allowScroll: PropTypes.bool
};

export default AppMenu;