import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Row, Text } from '../../../../../common';
import { Checkbox } from '../..';

const SubFilters = props => {
  const { items, onSubmit, initialCheckedItems } = props;
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const { t } = useTranslation();

  const handleOnChange = useCallback(key => () => {
    const keyExists = checkedItems.find(_ => _ === key);
    if (keyExists) {
      setCheckedItems(
        checkedItems.filter(_ => _ !== key)
      );
    } else {
      setCheckedItems([...checkedItems, key]);
    }

  }, [checkedItems]);

  const handleOnConfirm = useCallback(() => {
    onSubmit(checkedItems);
  }, [onSubmit, checkedItems]);

  const handleOnClear = useCallback(() => {
    onSubmit([]);
  }, [onSubmit]);

  return (
    <Row p={20} direction="column">
      {items.map(({ key, label }) => (
        <Row key={key} mb={10} align="center">
          <Checkbox
            rounded
            style={{ marginRight: 10 }}
            onChange={handleOnChange(key)}
            checked={checkedItems.some(_ => _ === key)}
          />
          <Text h4 newLine style={{ lineHeight: 1 }}>{label}</Text>
        </Row>
      ))}
      <Row full>
        <Button
          transparent
          style={{ color: '#494949', width: '50%' }}
          onClick={handleOnClear}
        >
          {t`Clear`}
        </Button>
        <Button
          htmlType="submit"
          onClick={handleOnConfirm}
          style={{ width: '50%', backgroundColor: '#000000', color: '#ffffff' }}
        >
          {t`Apply`}
        </Button>
      </Row>
    </Row>
  );
};

SubFilters.propTypes = {
  items: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialCheckedItems: PropTypes.array
};

SubFilters.defaultProps = {
  initialCheckedItems: []
};

export default SubFilters;