import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Text } from '../../../../common';

import './styles.less';

export const LoaderText = props => {
  const { t } = useTranslation();
  const { text } = props;

  return (
    <div className="loader-text-wrapper">
      <Text h1 white upper>
        {text || t`Loading`}
        <span className="dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </Text>
      <Text h1 white upper>
        {text || t`Loading`}
        <span className="dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </Text>
      <Text h1 white upper>
        {text || t`Loading`}
        <span className="dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </Text>
    </div>
  );
};

const Loader = props => {
  const { text } = props;

  useEffect(() => {
    const scrollPosition = window.scrollY;

    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    return () => {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');

      window.scrollTo(0, scrollPosition);
    };
  }, []);

  return (
    <div className="loader-container">
      <LoaderText text={text} />
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string
};

export default Loader;