import React, { useCallback } from 'react';
import { InputNumber } from 'antd';

import ErrorMessage from '../ErrorMessage';

const FormInputNumber = ({ field, form, className = '', ...props }) => {
  const { touched, setFieldTouched, setFieldValue } = form;
  const { onBlur } = props;
  const { name: fieldName } = field;

  const handleOnBlur = useCallback(
    fieldName => () => {
      setFieldTouched(fieldName);
      if (onBlur) {
        onBlur();
      }
    },
    [setFieldTouched, onBlur]
  );

  const handleOnChange = useCallback(
    fieldName => value => {
      setFieldValue(fieldName, value || '');
    },
    [setFieldValue]
  );

  return (
    <>
      <InputNumber
        {...field}
        {...props}
        className={`${className}`}
        onBlur={handleOnBlur(fieldName)}
        onChange={handleOnChange(fieldName)}
      />
      <div style={{ position: 'relative', height: 20, width: '100%' }}>
        {(touched[fieldName] && form.errors[fieldName]) && (
          <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{form.errors[fieldName]}</ErrorMessage>
        )}
      </div>
    </>
  );
};

FormInputNumber.propTypes = {};

export default FormInputNumber;