import React, { useCallback } from 'react';
import { Input } from 'antd';

import ErrorMessage from '../ErrorMessage';

const FormInput = ({ field, form, className = '', ...props }) => {
  const { touched } = form;
  const { onBlur } = props;
  const { name: fieldName } = field;

  const handleOnBlur = useCallback(
    fieldName => {
      form.setFieldTouched(fieldName);
      if (onBlur) {
        onBlur();
      }
    },
    [form, onBlur]
  );

  return (
    <>
      <Input {...field} {...props} className={`${className}`} onBlur={() => handleOnBlur(fieldName)} />
      <div style={{ position: 'relative', height: 20, width: '100%' }}>
        {(touched[fieldName] && form.errors[fieldName]) && (
          <ErrorMessage style={{ position: 'absolute', top: 0, left: 0 }}>{form.errors[fieldName]}</ErrorMessage>
        )}
      </div>
    </>
  );
};

FormInput.propTypes = {};

export default FormInput;